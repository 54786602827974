import React, { useState } from 'react'
import { post } from '../../service/api'
import { browserHistory } from 'react-router'
import ButtonsSave from '../../shared/components/buttons/save';
import { CurrencyMask } from '../../shared/helper/helperutil';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import ComponentForm from '../../shared/components/html/component-form';
import CustoIndireto from '../../models/CustoIndireto';

const CadastroCustoindireto = () => {

    const[custo] = useState<CustoIndireto>(new CustoIndireto(null));
    const [msg, setMsg] = useState<string>('')

    const cadastra = async (event) => {
        event.preventDefault();
        const body = {
            "nome": custo.nome,
            "descricao": custo.descricao,
            "valor": custo.valor
        };
        setMsg(`Custo Indireto: ${custo.nome} cadastrado com sucesso!`)
        post('/custo/indireto', body).then(browserHistory.push(`/consulta-custo-indireto?msg=${msg}`));
    }

    return (
        <>
            <TitlePage text={'Novo Custo indireto'} />
            <ComponentForm submit={cadastra} method="post">
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="nome-indireto"
                        placeholder="Nome Indireto"
                        value={custo.nome}
                        change={custo.inputChange}
                        label="Nome"
                        required={true}
                    />
                    <InputNumber
                        label="Preço custo indireto"
                        class="col-md-6 mb-3"
                        prefix={'R$'}
                        format={CurrencyMask}
                        value={custo.valor}
                        onChange={ custo.inputChange}
                        id="valor-indireto"
                        placeholder="R$ 0,00"
                    /> 
                </div>
                <div className="row">
                    <Input 
                        class="col-md-12 mb-3"
                        id="descricao-indireto"
                        placeholder="Descrição Indireto"
                        value={custo.descricao}
                        change={custo.inputChange}
                        label="Descrição"
                        required={true}
                    />
                </div>
                <ButtonsSave url={"/consulta-custo-indireto"}/>
            </ComponentForm>
        </>
    );
}
export default CadastroCustoindireto;