import { ChangeEvent } from "react";

export default class Tecido{
    readonly _id:string;
    private _nome:string;
    private _descricao:string;
    private _gramatura:string;
    private _valorMetro2:number;
    private _largura:number;
    private _sobra:number;

    constructor(repository:any){
        this._id = repository?._id;
        this._nome = repository?.nome;
        this._descricao = repository?.descricao;
        this._valorMetro2 = repository?.valorM2;
        this._largura = repository?.largura;
        this._gramatura = repository?.gramatura;
        this._sobra = repository?.sobra;
    }


    get nome(){
        return this._nome;
    }

    get descricao(){
        return this._descricao;
    }

    get valorM2(){
        return this._valorMetro2;
    }

    get largura(){
        return this._largura;
    }

    get gramatura(){
       return this._gramatura;
    }

    get sobra(){
        return this._sobra;
    }

    inputChange = (e: ChangeEvent<HTMLInputElement>) : void  => {
        const element = e.target;
        const name = `_${element.name.replace('-tecido', '')}`;
        if( `_nome` ===  name || `_descricao` ===  name || `_gramatura` ===  name){
            this[name] = element.value;
        }else{
            this[name] = isNaN(Number(element.value)) ? Number(element.value.replace(/\D/g, '')) : Number(element.value);
        }
    }
}