import { ChangeEvent } from "react";

export class Tamanho{
    readonly _id?:string;
    private _nome?:String;
    private _minimoLargura?:number;
    private _maximoLargura?:number;
    private _minimoAltura?:number;
    private _maximoAltura?:number;

    constructor(repository?: any){
        if(repository){
            this._id = repository?._id ? repository?._id : `INVALID_ID` ;
            this._nome = repository?.nome;
            this._minimoAltura = repository?.minimoAltura;
            this._maximoAltura = repository?.maximoAltura;
            this._maximoLargura = repository?.maximoLargura;
            this._minimoLargura = repository?.minimoLargura;
        }
    }

    get nome(){
        return this._nome;
    }

    get minimoLargura(){
        return this._minimoLargura;
    }


    get maximoLargura(){
        return this._maximoLargura;
    }

    get minimoAltura(){
        return this._minimoAltura;
    }

    get maximoAltura(){
        return this._maximoAltura
    }

    inputChange = (e: ChangeEvent<HTMLInputElement>) : void  => {
        const element = e.target;
        const name = `_${element.name}`;
        if( `_nome` ===  name){
            this[name] = element.value;
        }else{
            this[name] = isNaN(Number(element.value)) ? Number(element.value.replace(/\D/g, '')) : Number(element.value);
        }
    }
}