import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import api from '../../service/api';
import { CurrencyMask, CurrencyMaskForTable, FormatCurrencyForBackEnd, StringForNumber } from '../../shared/helper/helperutil';
import ListFornecedores from '../../shared/components/util/list-fornecedores-acessorios';
import ButtonsAlter from '../../shared/components/buttons/update';
import TitlePage from '../../shared/components/html/title';
import Select from '../../shared/components/html/select';
import Input from '../../shared/components/html/input';
import InputNumber from '../../shared/components/html/input-for-number';
import AddFornecedor from '../../shared/components/util/add-fornecedores';
import Acessorio from '../../models/Acessorio';
import ComponentForm from '../../shared/components/html/component-form';
import { Fornecedor } from '../../models/Fornecedor';
import ConsultaLucro from '../lucro/consulta-lucro';

const AlteraAcessorio = (props:any ) =>{

    const [acessorio, setAcessorio] = useState<Acessorio>(new Acessorio(null));
    const [id] = useState(props.location.query.id);
    const [fornecedor, setFornecedor] = useState<Fornecedor>(new Fornecedor());
    const [msg, setMsg] = useState('');

    useEffect(() => {
        if (id) {
            getItem();
        }else{
            goTo();
        }
    }, [id]);

    const goTo = () => browserHistory.push('/consulta-acessorio');

    const getItem = async () => {
        try {
            const { data: repository } = await api.get(`/acessorio/${id}`);
            if(!repository) goTo();
            setAcessorio(new Acessorio(repository));
        } catch (err) {
            console.error(err);
        }
    }

    const altera = async event => {
        event.preventDefault()
        try {
            const body = {
                "nome": acessorio.nome,
                "tipo": acessorio.tipo,
                "descricao": acessorio.descricao,
                "largura": acessorio.largura,
                "fornecedores" : acessorio?.fornecedores
            }
            setMsg(`Acessório: ${acessorio.nome} atualizado com sucesso!`)
            await api.put(`/acessorio/${id}`, body)
            browserHistory.push(`/consulta-acessorio?msg=atualizado`)
        } catch (err) {
            console.error(err)
            setMsg('Houve um problema com o login, verifique seu usuário e senha!')

        }
    }

    const addNewElementArray = () => {
        acessorio?.fornecedores?.push(fornecedor);
        setFornecedor(new Fornecedor())
    }

    const removeElementArray = (res) => {
        acessorio.removeFornecedorById(res?.id);
        setMsg(`Element ${res.id} excluido`)
    }

    return (
        <>
            <TitlePage text={'Atualizar Acessório'} />
            <ComponentForm submit={altera} method="put">
                <div className="row">
                    <Input 
                        class="col-md-4 mb-3"
                        id="nome"
                        placeholder="Nome do acessório"
                        value={acessorio.nome}
                        change={(e) => {acessorio.inputChange(e); setMsg(e.target.value)}}
                        label="Nome"
                        required={true}
                    />
                    <InputNumber
                        label="Largura"
                        class="col-md-4 mb-3"
                        thousandSeparator={true} 
                        suffix={' cm'}
                        onValueChange={(e) => {acessorio.inputNumberChange(e.value, 'largura'); setMsg(e.value)}}
                        value={acessorio.largura}
                        id="largura"
                        placeholder="Largura do acessório"
                    />
                </div>
                <div className="row">
                    <Select value={acessorio.tipo} class="col-md-4 mb-3" id="tipo" label="Tipo de valor" change={(e) => {acessorio.inputChange(e); setMsg(e.target.value)}} required={true} >
                        <option value=""> Selecione um tipo</option>
                        <option value="unidade"> Valor Unitário </option>
                        <option value="metros"> Metros </option>
                    </Select>
                </div>
                <div className="row">
                    <Input 
                        class="col-md-12 mb-3"
                        id="descricao"
                        placeholder="Descrição do acessório"
                        value={acessorio.descricao}
                        change={(e) => {acessorio.inputChange(e); setMsg(e.target.value)}}
                        label="Descrição"
                        required={true}
                    />
                </div>
                <AddFornecedor addFornecedor={addNewElementArray} >
                    <Input 
                        class="col-md-3 mb-3"
                        id="nome-fornecedor"
                        placeholder="Nome do fornecedor"
                        value={fornecedor.Nome || ``}
                        change={(e) => {fornecedor.inputChange(e); setMsg(e.target.value)}}
                        label="Nome"  
                    />
                    <Input 
                        class="col-md-3 mb-3"
                        id="rendimento-fornecedor"
                        placeholder="Rendimento"
                        value={fornecedor.Rendimento || 0}
                        change={(e) => {fornecedor.inputChange(e); setMsg(e.target.value)}}
                        label="Rendimento"
                        
                    />
                    <InputNumber
                        label="Custo"
                        class="col-md-3 mb-3"
                        format={CurrencyMask}
                        value={fornecedor.Custo || ``}
                        onChange={(e) => {fornecedor.inputChange(e); setMsg(e.target.value)}}
                        prefix={'R$'}
                        id="custo-fornecedor"
                        placeholder="R$ 0,00"
                    />
                    <InputNumber
                        label="Valor"
                        class="col-md-3 mb-3"
                        value={fornecedor.Valor || 0} 
                        format={CurrencyMaskForTable}
                        id="valor-fornecedor"
                        type="text"
                    />
                </AddFornecedor>
                <ListFornecedores fornecedores={acessorio.fornecedores} callback={(res) => removeElementArray(res)} />
                <ButtonsAlter url={"acessorio"}/>
            </ComponentForm>
        </>
    );
}
export default React.memo(AlteraAcessorio);