import React from 'react'

const Select = (props) => {
    const required = props.required ? 'required' : '';
    const value = props.value?._id ? props.value._id : props.value;
    return (
    <div className={props.class}>
        <label htmlFor={props.id} className="label label-danger">{props.label}</label>
        <select name={props.id} value={value} id={props.id} className="form-control" required={required} onChange={props.change}>
            {props.children}
        </select>
    </div>)
}
    
export default React.memo(Select);