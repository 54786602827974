import React, { useState } from 'react';
import { post } from '../../service/api';
import ButtonsSave from '../../shared/components/buttons/save';
import { browserHistory } from 'react-router';
import {PercentMask, FormatPercentForBackEnd } from '../../shared/helper/helperutil';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import Lucro from '../../models/Lucro';
import ComponentForm from '../../shared/components/html/component-form';

const CadastroLucro = () => {

    const [lucro] = useState<Lucro>(new Lucro(null));
    const [msg, setMsg] = useState<string>('')

    const cadastra = async event => {
        event.preventDefault();
        const body = {
            "quantidade": lucro.quantidade,
            "valor": lucro.valor
        };
        setMsg(`Lucro cadastrado com sucesso!`)
        post('/lucro', body).then( () => browserHistory.push(`/consulta-lucro?msg=${msg}`));
    }

    return (
        <>
            <TitlePage text={'Novo Lucro'} />
            <ComponentForm submit={cadastra} method="post"> 
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="quantidade-lucro"
                        placeholder="Quantidade do lucro"
                        value={lucro.quantidade}
                        change={lucro.inputChange}
                        label="Quantidade"
                        required={true}
                    />
                    <InputNumber
                        label="Valor"
                        class="col-md-6 mb-3"
                        suffix={'%'}
                        format={PercentMask}
                        value={lucro.valor}
                        onChange={lucro.inputChange}
                        id="valor-lucro"
                        placeholder="0 %"
                    />
                </div>
                <ButtonsSave url={"/consulta-lucro"} />
            </ComponentForm>
        </>
    );
}
export default CadastroLucro;