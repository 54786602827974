import { ChangeEvent } from "react";
import { Tamanho } from "./Tamanho";


class Costura{
    readonly _id: string;
    private _nome?:string;
    private _descricao?:string;
    private _valor?:number;
    private _tempo?:String;
    private _tamanho?:Tamanho;

    constructor(repository: any){
        this._id = repository?._id;
        this._nome = repository?.nome;
        this._descricao = repository?.descricao;
        this._valor = repository?.valor;
        this._tempo = repository?.tempo;
        this._tamanho = repository?.tamanho;
    }

    get nome(){
        return this._nome;
    }

    get descricao(){
        return this._descricao;
    }

    get valor(){
        return this._valor;
    }

    get tempo(){
        return this._tempo;
    }

    get tamanho(){
        return this._tamanho;
    }

    get tamanhoForPayload(){
        return {
            "_id": this._tamanho?._id,
            "nome": this._tamanho?.nome,
            "minimoAltura": this._tamanho?.minimoAltura,
            "maximoAltura": this._tamanho?.maximoAltura,
            "maximoLargura": this._tamanho?.maximoLargura,
            "minimoLargura":this._tamanho?.minimoLargura
        };
        
    }


    inputChange = (e: ChangeEvent<HTMLInputElement>) : void  => {
        const element = e.target;
        const name = `_${element.name.replace('-costura', '')}`;
        if( `number` ===  typeof(this[name])){
           this[name] = isNaN(Number(element.value)) ? Number(element.value.replace(/\D/g, '')) : Number(element.value);
        }else{
            this[name] = element.value;
        }
    }

    tamanhoSelected = (e: ChangeEvent<HTMLInputElement>, tamanhos:Array<Tamanho>) :void => {
        const selectedValue = e.target.value;
        const selectedTamanho = tamanhos.find((tamanho) => (tamanho.nome === selectedValue) || (tamanho._id === selectedValue) );
        if (selectedTamanho) {
            this._tamanho = selectedTamanho;
        }
    } 
}

export default Costura;