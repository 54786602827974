import React, {useState, useEffect} from 'react';
import { browserHistory } from 'react-router';
import { post } from '../../service/api';
import ButtonsSave from '../../shared/components/buttons/save';
import { CurrencyMask} from '../../shared/helper/helperutil';
import ListFornecedores from '../../shared/components/util/list-fornecedores-acessorios';
import AddFornecedor from '../../shared/components/util/add-fornecedores';
import { CurrencyMaskForTable } from '../../shared/helper/helperutil';
import TitlePage from '../../shared/components/html/title';
import Input from '../../shared/components/html/input';
import Select from '../../shared/components/html/select';
import InputNumber from '../../shared/components/html/input-for-number';
import Acessorio from '../../models/Acessorio';
import ComponentForm from '../../shared/components/html/component-form';
import { Fornecedor } from '../../models/Fornecedor';




const CadastroAcessorio = () => {

    const[acessorio] = useState<Acessorio>(new Acessorio(null));    
    const [fornecedor, setFornecedor] = useState<Fornecedor>(new Fornecedor());
    const [msg, setMsg] = useState('');

    const cadastra = async event => {
        event.preventDefault();
        const body = {
            "nome": acessorio.nome,
            "descricao": acessorio.descricao,
            "tipo": acessorio.tipo,
            "largura": acessorio.largura,
            "fornecedores": acessorio.fornecedores
        };
        setMsg(`Acessorio: ${acessorio.nome} cadatrado com sucesso!`)
        await post('/acessorio', body) && browserHistory.push(`/consulta-acessorio?msg=cadatrado`)
    }

    const addNewElementArray = () => {
        acessorio?.fornecedores?.push(fornecedor);
        setFornecedor(new Fornecedor())
    }

    const removeElementArray = (res) =>  {
        acessorio.removeFornecedorById(res?.id);
        setMsg(`Element ${res.id} excluido`)
        
    }

    return (
        <>
            <TitlePage text={'Novo Acessório'} />
            <ComponentForm submit={cadastra} method="post">
                    <div className="row">
                        <Input 
                            class="col-md-4 mb-3"
                            id="nome"
                            placeholder="Nome do acessório"
                            value={acessorio.nome}
                            change={acessorio.inputChange}
                            label="Nome"
                            required={true}
                        />
                        <InputNumber
                            label="Largura"
                            class="col-md-4 mb-3"
                            thousandSeparator={true} 
                            suffix={' cm'}
                            value={acessorio.largura}
                            onChange={ acessorio.inputChange}
                            id="largura"
                            placeholder="Largura do acessório"
                        />
                    </div>
                    <div className="row">
                        <Select class="col-md-4 mb-3" id="tipo" label="Tipo de valor" change={acessorio.inputChange} required={true} >
                            <option value=""> Selecione um tipo</option>
                            <option value="unidade"> Valor Unitário </option>
                            <option value="metros"> Metros </option>
                        </Select>
                    </div>
                    <div className="row">
                        <Input 
                            class="col-md-12 mb-3"
                            id="descricao"
                            placeholder="Descrição do acessório"
                            value={acessorio.descricao}
                            change={acessorio.inputChange}
                            label="Descrição"
                            required={true}
                        />
                    </div>
                    <AddFornecedor addFornecedor={addNewElementArray} >
                        <Input 
                            class="col-md-3 mb-3"
                            id="nome-fornecedor"
                            placeholder="Nome do fornecedor"
                            value={fornecedor.Nome || ``}
                            change={(e) => {fornecedor.inputChange(e); setMsg(e.target.value)}}
                            label="Nome"  
                        />
                        <Input 
                            type="number"
                            class="col-md-3 mb-3"
                            id="rendimento-fornecedor"
                            placeholder="Rendimento"
                            value={fornecedor.Rendimento || 0}
                            change={(e) => {fornecedor.inputChange(e); setMsg(e.target.value)}}
                            label="Rendimento"
                            
                        />
                        <InputNumber
                            label="Custo"
                            class="col-md-3 mb-3"
                            format={CurrencyMask}
                            value={fornecedor.Custo || ``}
                            onChange={(e) => {fornecedor.inputChange(e); setMsg(e.target.value)}}
                            prefix={'R$'}
                            id="custo-fornecedor"
                            placeholder="R$ 0,00"
                        />
                        <InputNumber
                            label="Valor"
                            class="col-md-3 mb-3"
                            value={fornecedor.Valor || ``} 
                            format={CurrencyMaskForTable}
                            id="valor-fornecedores"
                            type="text"
                        />
                    </AddFornecedor>
                    <ListFornecedores fornecedores={acessorio?.fornecedores} callback={(res) => removeElementArray(res)} />
                    <ButtonsSave url={"/consulta-acessorio"} />
            </ComponentForm>
        </>
    )   
}
export default React.memo(CadastroAcessorio);