import React, { Component, useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { browserHistory, Link } from 'react-router';
import api from '../../service/api';
import { CurrencyMask } from '../../shared/helper/helperutil';
import ButtonsAlter from '../../shared/components/buttons/update';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import ComponentForm from '../../shared/components/html/component-form';
import CustoLinha from '../../models/CustoLinha';

 const AlterarCustoLinha = (props:any) => {

    const [custo, setCusto] = useState<CustoLinha>(new CustoLinha(null));
    const [id] = useState<string>(props.location.query.id);
    const [msg, setMsg] = useState<string>('');

    useEffect(() => {
        if(id){
            getItem()
        }else{
            goTo()
        }
    },[])

    const getItem = async () => {
        try {
            const { data: repository } = await api.get(`/custo/linha/${id}`)
            setCusto(new CustoLinha(repository)); 
        } catch (err) {
            console.error(err)
        }
    }

    const altera = async event => {
        event.preventDefault();
        try {
            await api.put(`/custo/linha/${id}`,
                {
                    "nome": custo.nome,
                    "descricao": custo.descricao,
                    "valor": custo.valor
                }
            )
            .then(() => setMsg(`Custo de linha: ${custo.nome} atualizado com sucesso!`) )
            .then(() => goTo());
        } catch (err) {
            setMsg('Houve um problema com o login, verifique seu usuário e senha!')
        }
    }

    const goTo = () => browserHistory.push(`/consulta-custo-linha?${msg ? `msg=${msg}` : ``}`)
    
    return (
        <>
            <TitlePage text={'Atualizar Custo Linha'} />
            <ComponentForm submit={altera} method="put">
                    <div className="row">
                        <Input 
                            class="col-md-6 mb-3"
                            id="nome-linha"
                            placeholder="Nome da linha"
                            value={custo.nome}
                            change={(e) => {custo.inputChange(e); setMsg(e.target.value)}}
                            label="Nome"
                            required={true}
                        />
                        <InputNumber
                            label="Preço da linha"
                            class="col-md-6 mb-3"
                            prefix={'R$'}
                            format={CurrencyMask}
                            value={custo.valor}
                            onChange={(e) => {custo.inputChange(e); setMsg(e.target.value)}}
                            id="valor-linha"
                            placeholder="R$ 0,00"
                        />
                    </div>
                    <div className="row">
                        <Input 
                            class="col-md-12 mb-3"
                            id="descricao-linha"
                            placeholder="Descrição do custo linha"
                            value={custo.descricao}
                            change={(e) => {custo.inputChange(e); setMsg(e.target.value)}}
                            label="Descrição"
                            required={true}
                        />
                    </div>
                    <ButtonsAlter url={"custo-linha"}/>
            </ComponentForm>
        </>
    )
}

export default React.memo(AlterarCustoLinha);