import React from 'react';

const ButtonsPagination = (props) => props.page.length > 1 && 
(
    <div className="container-fluid">
        <nav className="nav justify-content-end row justify-content-md-center">
            <ul className="pagination">
                <li className="page-item">
                    <button className={props.currentPage < 1 ? "btn" : "page-link"} disabled={props.currentPage < 1}
                        onClick={props.backPage}>
                        <span aria-hidden="true">&laquo;</span>
                    </button>
                </li>
                {
                    props.page.map(res =>
                        <li className="page-item" key={res}>
                            <button className={props.currentPage === res ? "btn btn-primary" : "page-link"}
                                disabled={props.currentPage === res ? true : false}
                                onClick={() => props.currentElement(res)}>
                                <span aria-hidden="true">{res + 1}</span>
                            </button>
                        </li>
                    )
                }
                <li className="page-item" >
                    <button className={(props.currentPage + 1) === props.page.length ? "btn" : "page-link"}
                        disabled={(props.currentPage + 1) === props.page.length}
                        onClick={props.nextPage}>
                        <span aria-hidden="true">&raquo;</span>
                    </button>
                </li>
            </ul>
        </nav>
    </div>
);

export default React.memo(ButtonsPagination);
