import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { get, delet } from '../../service/api';
import { CurrencyMask, CurrencyMaskForTable } from '../../shared/helper/helperutil'
import ButtonsConsulta from '../../shared/components/buttons/consulta';
import ButtonsPagination from '../../shared/components/buttons/pagination';
import ModalExcluir  from '../../shared/components/modals/modal-excluir'
import MsgErroTable from '../../shared/components/util/msg-erro-table';
import HeaderForConsulta from '../../shared/components/util/header-for-consulta';
import Cordao from '../../models/Cordao';


const ConsultaCordao = (props) => {

    const [limitPagination, setLimitPagination] = useState<number>(5);
    const [page, setPage] = useState<Array<Number>>([]);
    const [pag, setPag] = useState<number>(0);
    const [modalExcluir, setModalExcluir] = useState<boolean>(false);
    const [searchElement, setSearchElement] = useState<string>('');
    const [msg, setMsg] = useState('');
    const [quantityItens, setQuantityItens] = useState(0);
  
  
    const [list, setList] = useState<Array<Cordao>>([]);
    const [idSelected, setIdSelected] = useState<string>('');
    
  

    useEffect( () => {
        setMsg(props.location.query.msg || '');
        getList()
    },[]);

    useEffect( () => getList() ,[pag, searchElement, limitPagination]);

    useEffect( () => {
        const newPage:Array<Number> = new Array();
        const maximumPagination = quantityItens / limitPagination;
        for(let item = 0; item < maximumPagination; item++){
            newPage.push(item)
        }
        setPage(newPage);
    } ,[quantityItens, limitPagination]);

    useEffect( () => {
        getList();
        setIdSelected('');
        setLimitPagination(5);
        setPag(0)
    } ,[msg]);
    
    const handleClose = () => setModalExcluir(false);

    const handleShow = (item?: string) => {
        setIdSelected(item ? item : '');
        setModalExcluir(true);
    }

    const backPage = () => setPag( pag - 1 );

    const nextPage = () => setPag( pag + 1 );

    const search = (e) => setSearchElement(e);
    
    const changePagination = (e) => {
        setLimitPagination(e)
        setPag(0);
    };

    const changePag = (e) => setPag(e);


    const getList: () => void = async () => {
        const url = `/cordao?limit=${limitPagination}&skip=${limitPagination * pag}&name=${searchElement}`;
        const repository = await get(url);
        setQuantityItens(repository.total);
        setList(repository.docs);
    }

    const deleteItem = async () => {
        await delet(`/cordao/${idSelected}`);
        handleClose();
        setMsg('excluído');   
    }

    return (
        <>
            <HeaderForConsulta
                 component='Cordão'
                 msg={msg}
                 search={search}
                 name={searchElement}
                 changePagination={changePagination}
                 list={list.length}
                 valueSelect={limitPagination}
            />
            <table className="table table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th></th>
                        <th scope="col">Nome</th>
                        <th scope="col">Custo</th>
                        <th scope="col">Rendimento</th>
                        <th scope="col">Espessura</th>
                        <th scope="col">Descrição</th>
                    </tr>
                </thead>
                <tbody>
                    {list.length > 0 ? list.map(cordao =>
                        <tr key={cordao._id}>
                            <td>
                                <ButtonsConsulta url={`/alterar-cordao?id=${cordao._id}`} 
                                    click={() => handleShow(cordao._id)}
                                />
                            </td>
                            <td>{cordao.nome}</td>
                            <td>
                                <CurrencyFormat displayType={'text'}
                                    value={cordao.custo} format={CurrencyMask}>
                                </CurrencyFormat>
                            </td>
                            <td>{cordao.rendimento}</td>
                            <td>
                                <CurrencyFormat thousandSeparator={true} suffix={'m '} displayType={'text'}
                                    value={cordao.espessura} >
                                </CurrencyFormat>
                            </td>
                            <td>{cordao.descricao}</td>
                        </tr>
                    ) : <MsgErroTable colunas="6"/>
                    }
                </tbody>
            </table>
            <ButtonsPagination
                currentElement={ (e) => changePag(e) }
                page={page}
                currentPage={pag}
                nextPage={nextPage}
                backPage={backPage}
            />
            <ModalExcluir 
                texto={`Cordão`}
                show={modalExcluir}
                hide={handleClose}
                delete={deleteItem}
            />
        </>
    )
}

export default ConsultaCordao;