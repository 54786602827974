import { Fornecedor } from "./Fornecedor";
import { ChangeEvent } from "react";

class Acessorio{
    
    readonly _id?:string;
    private _nome?: string;
    private _largura?: number;
    private _descricao?: string;
    private _tipo?: any;
    private _fornecedores?: Array<Fornecedor>;

    constructor(repository:any){
        this._id = repository?.id;
        this._nome = repository?.nome;
        this._largura = repository?.largura;
        this._descricao = repository?.descricao;
        this._tipo = repository?.tipo;
        this._fornecedores =  repository?.fornecedores ? repository?.fornecedores : new Array;
    }

    get nome(){
        return this._nome;
    }

    get largura(){
        return this._largura
    }

    get descricao(){
        return this._descricao;
    }

    get tipo(){
        return this._tipo;
    }

    get fornecedores(){
        return this._fornecedores;
    }

    inputChange = (e: ChangeEvent<HTMLInputElement>) : void  => {
        const element = e.target;
        const name = `_${element.name}`;
        if( name != `_largura`){
            this[name] = element.value;
        }else{
            this[name] = isNaN(Number(element.value)) ? Number(element.value.replace(/\D/g, '')) : Number(element.value);
        }
    }

    inputNumberChange = (element: any, name: string) : void  => {
        if( `_largura` !=   `_${name}`){
            this[`_${name}`] = element.value;
        }else{
            this[`_${name}`] = isNaN(Number(element)) ? Number(element.replace(/\D/g, '')) : Number(element);
        }
    }

    addFornecedor (fornecedor:Fornecedor): void {
        this._fornecedores?.push(fornecedor);
    }

    addAllFornecedor (fornecedores:Array<Fornecedor>): void {
        this._fornecedores?.push(...fornecedores);
    }

    removeFornecedorById(id:string): void{
        if(this._fornecedores){
          this._fornecedores = this._fornecedores?.filter(r => r.id != id)
        } 
    }

}

export default Acessorio;