import {browserHistory} from  'react-router';

export const TOKEN_KEY = "@embalabrindes-token"

export const isAuthenticated = (nextState:any,replace:any) => 
    localStorage.getItem(TOKEN_KEY) === null ? 
        replace('/?msg=você precisa estar logado para acessar') : 
        nextState

export const getToken = () => localStorage.getItem(TOKEN_KEY)

export const login = (token:string) =>  localStorage.setItem(TOKEN_KEY, token)

export const deleteToken = () => localStorage.removeItem(TOKEN_KEY)

export const SessionExpired = () => {
    localStorage.removeItem(TOKEN_KEY)
    browserHistory.push('/');
    
}


