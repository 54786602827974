import { ChangeEvent } from "react";

class CustoIndireto{
    readonly _id:string;
    private _nome:string;
    private _descricao:string;
    private _valor:number;

    constructor(repository:any){
        this._id = repository?._id;
        this._nome = repository?.nome;
        this._descricao = repository?. descricao;
        this._valor = repository?.valor;
    }

    get nome(){
        return this._nome;
    }

    get descricao(){
        return this._descricao;
    }

    get valor(){
        return this._valor;
    }

    inputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const element = e.target;
        const name = `_${element.name.replace('-indireto', '')}`;
        if(name === '_valor'){
            this[name] = isNaN(Number(element.value)) ? Number(element.value.replace(/\D/g, '')) : Number(element.value);
        } else{
            this[name] = element.value;
        }
    }
    
}

export default CustoIndireto;