import React, { useState } from 'react';
import { post } from '../../service/api';
import ButtonsSave from '../../shared/components/buttons/save';
import { browserHistory } from 'react-router';
import { PercentMask } from '../../shared/helper/helperutil';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import Imposto from '../../models/Imposto';
import ComponentForm from '../../shared/components/html/component-form';


const CadastroImposto = () => {

    const [imposto] = useState<Imposto>(new Imposto(null));

    const cadastra = async event => {
        event.preventDefault();
        const body =  {
            "nome": imposto.nome,
            "descricao": imposto.descricao,
            "valor": imposto.valor,
        };
        post('/imposto', body).then(() => browserHistory.push('/consulta-imposto?msg=cadastrado'));
    }

    return (
        <>
            <TitlePage text={'Novo Imposto'} />
            <ComponentForm submit={cadastra} method="post"> 
                <div className="row">
                    <Input 
                        value={imposto.nome}
                        change={imposto.inputChange}
                        class="col-md-6 mb-3"
                        id="nome-imposto"
                        placeholder="Nome do imposto"
                        label="Nome"
                        required={true}
                    />
                    <InputNumber
                            label="Valor"
                            class="col-md-6 mb-3"
                            thousandSeparator={true} 
                            suffix={' %'}
                            format={PercentMask}
                            value={imposto.valor}
                            onChange={imposto.inputChange}
                            id="valor-imposto"
                            placeholder="0 %"
                    />
                </div>
                <div className="row">
                    <Input 
                        class="col-md-12 mb-3"
                        id="descricao-imposto"
                        placeholder="Descrição do imposto"
                        value={imposto.descricao}
                        change={imposto.inputChange}
                        label="Descriçao"
                        required={true}
                    />
                </div>
                <ButtonsSave url={"/consulta-imposto"}/>
            </ComponentForm>
        </>
    );
}
export default CadastroImposto;