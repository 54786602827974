import React from 'react'
import ReactDOM from 'react-dom'
import { browserHistory, Route, Router } from 'react-router'
import App from './App'
import Login from "./login/login"
import Logout from "./login/logout"
import AlterAcessorio from './produto/acessorio/altera-acessorio'
import CadastroAcessorio from './produto/acessorio/cadastro-acessorio'
import ConsultaAcessorio from './produto/acessorio/consulta-acessorio'
import AlterarCordao from './produto/cordao/altera-cordao'
import CadastroCordao from './produto/cordao/cadastro-cordao'
import ConsultaCordao from './produto/cordao/consulta-cordao'
import AlterarCostura from './produto/costura/alterar-costura'
import CadastroCostura from './produto/costura/cadastro-costura'
import ConsultaCostura from './produto/costura/consulta-costura'
import AlterarCustoIndireto from './produto/custo-indireto/alterar-custo-indireto'
import CadastroCustoIndireto from './produto/custo-indireto/cadastro-custo-indireto'
import ConsultaCustoIndireto from './produto/custo-indireto/consulta-custo-indireto'
import AlterarCustoLinha from './produto/custo-linha/alterar-custo-linha'
import CadastroCustoLinha from './produto/custo-linha/cadastro-custo-linha'
import ConsultaCustoLinha from './produto/custo-linha/consulta-custo-linha'
import AlterarEstampa from './produto/estampa/alterar-estampa'
import CadastroEstampa from './produto/estampa/cadastro-estampa'
import ConsultaEstampa from './produto/estampa/consulta-estampa'
import AlterarFita from './produto/fita/alterar-fita'
import CadastroFita from './produto/fita/cadastro-fita'
import ConsultaFita from './produto/fita/consulta-fita'
import AlterarFotolito from './produto/fotolito/alterar-fotolito'
import CadastroFotolito from './produto/fotolito/cadastro-fotolito'
import ConsultaFotolito from './produto/fotolito/consulta-fotolito'
import AlterarImposto from './produto/imposto/alterar-imposto'
import CadastroImposto from './produto/imposto/cadastro-imposto'
import ConsultaImposto from './produto/imposto/consulta-imposto'
import AlterarLucro from './produto/lucro/alterar-lucro'
import CadastroLucro from './produto/lucro/cadastro-lucro'
import ConsultaLucro from './produto/lucro/consulta-lucro'
import AlterarOrcamento from './produto/orcamento/alterar-orcamento'
import CadastroOrcamento from './produto/orcamento/cadastro-orcamento'
import ConsultaOrcamento from './produto/orcamento/consulta-orcamento'
import AlterarTamanho from './produto/tamanho/alterar-tamanho'
import CadastroTamanho from './produto/tamanho/cadastro-tamanho'
import ConsultaTamanho from './produto/tamanho/consulta-tamanho'
import AlterarTecido from './produto/tecido/alterar-tecido'
import CadastroTecido from './produto/tecido/cadastro-tecidos'
import ConsultaTecido from './produto/tecido/consulta-tecido'
import { isAuthenticated } from './service/auth'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
    <Router history={browserHistory}>
        <Route exact path="/" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/menu" component={App} onEnter={isAuthenticated}>
            <Route path="/alterar-acessorio" component={AlterAcessorio} onEnter={isAuthenticated} />
            <Route path="/cadastro-acessorio" component={CadastroAcessorio} onEnter={isAuthenticated} />
            <Route path="/consulta-acessorio" component={ConsultaAcessorio} onEnter={isAuthenticated} />
            <Route path="/alterar-cordao" component={AlterarCordao} onEnter={isAuthenticated} />
            <Route path="/cadastro-cordao" component={CadastroCordao} onEnter={isAuthenticated} />
            <Route path="/consulta-cordao" component={ConsultaCordao} onEnter={isAuthenticated} />
            <Route path="/alterar-costura" component={AlterarCostura} onEnter={isAuthenticated} />
            <Route path="/cadastro-costura" component={CadastroCostura} onEnter={isAuthenticated} />
            <Route path="/consulta-costura" component={ConsultaCostura} onEnter={isAuthenticated} />
            <Route path="/alterar-custo-indireto" component={AlterarCustoIndireto} onEnter={isAuthenticated} />
            <Route path="/cadastro-custo-indireto" component={CadastroCustoIndireto} onEnter={isAuthenticated} />
            <Route path="/consulta-custo-indireto" component={ConsultaCustoIndireto} onEnter={isAuthenticated} />
            <Route path="/alterar-custo-linha" component={AlterarCustoLinha} onEnter={isAuthenticated} />
            <Route path="/cadastro-custo-linha" component={CadastroCustoLinha} onEnter={isAuthenticated} />
            <Route path="/consulta-custo-linha" component={ConsultaCustoLinha} onEnter={isAuthenticated} />
            <Route path="/alterar-estampa" component={AlterarEstampa} onEnter={isAuthenticated} />
            <Route path="/cadastro-estampa" component={CadastroEstampa} onEnter={isAuthenticated} />
            <Route path="/consulta-estampa" component={ConsultaEstampa} onEnter={isAuthenticated} />
            <Route path="/alterar-fita" component={AlterarFita} onEnter={isAuthenticated} />
            <Route path="/cadastro-fita" component={CadastroFita} onEnter={isAuthenticated} />
            <Route path="/consulta-fita" component={ConsultaFita} onEnter={isAuthenticated} />
            <Route path="/alterar-fotolito" component={AlterarFotolito} onEnter={isAuthenticated} />
            <Route path="/cadastro-fotolito" component={CadastroFotolito} onEnter={isAuthenticated} />
            <Route path="/consulta-fotolito" component={ConsultaFotolito} onEnter={isAuthenticated} />
            <Route path="/alterar-imposto" component={AlterarImposto} onEnter={isAuthenticated} />
            <Route path="/cadastro-imposto" component={CadastroImposto} onEnter={isAuthenticated} />
            <Route path="/consulta-imposto" component={ConsultaImposto} onEnter={isAuthenticated} />
            <Route path="/alterar-lucro" component={AlterarLucro} onEnter={isAuthenticated} />
            <Route path="/cadastro-lucro" component={CadastroLucro} onEnter={isAuthenticated} />
            <Route path="/consulta-lucro" component={ConsultaLucro} onEnter={isAuthenticated} />
            <Route path="/alterar-orcamento" component={AlterarOrcamento} onEnter={isAuthenticated} />
            <Route path="/cadastro-orcamento" component={CadastroOrcamento} onEnter={isAuthenticated} />
            <Route path="/consulta-orcamento" component={ConsultaOrcamento} onEnter={isAuthenticated} />
            <Route path="/alterar-tamanho" component={AlterarTamanho} onEnter={isAuthenticated} />
            <Route path="/cadastro-tamanho" component={CadastroTamanho} onEnter={isAuthenticated} />
            <Route path="/consulta-tamanho" component={ConsultaTamanho} onEnter={isAuthenticated} />
            <Route path="/alterar-tecido" component={AlterarTecido} onEnter={isAuthenticated} />
            <Route path="/cadastro-tecido" component={CadastroTecido} onEnter={isAuthenticated} />
            <Route path="/consulta-tecido" component={ConsultaTecido} onEnter={isAuthenticated} />
        </Route>
    </Router>,
    document.getElementById('root')
);

serviceWorker.unregister();
