import React, { Component, useEffect, useState } from 'react';
import { post, get } from '../../service/api';
import ButtonsSave from '../../shared/components/buttons/save';
import { browserHistory } from 'react-router';
import SelectEasy from '../../shared/components/html/select-easy';
import TitlePage from '../../shared/components/html/title';
import Card from '../../shared/components/html/card';
import Input from '../../shared/components/html/input';
import Orcamento from '../../models/Order/Orcamento';
import Cliente from '../../models/costumer/Cliente';
import ComponentForm from '../../shared/components/html/component-form';
import CorteVT from '../../models/Order/CorteVT';
import Tecido from '../../models/Tecido';
import Acessorio from '../../models/Acessorio';
import Costura from '../../models/Constura';
import Cordao from '../../models/Cordao';
import CustoIndireto from '../../models/CustoIndireto';
import Fita from '../../models/Fita';
import Fotolito from '../../models/Fotolito';
import Imposto from '../../models/Imposto';
import Lucro from '../../models/Lucro';
import Select from '../../shared/components/html/select';


const CadastroOrcamento = () => {
    const { estados } =  require('../../shared/others/for-selects.json');

    const [orcamento, setOrcamento] = useState<Orcamento>(new Orcamento(null));
    const [cliente, setCliente] = useState<Cliente>(new Cliente(null));
    const [corte, setCorte] = useState<CorteVT>(new CorteVT(null));

    //selects
    const [acessorios, setAcessorios] = useState<Array<Acessorio>>([]);
    const [costuras, setCosturas] = useState<Array<Costura>>([]);
    const [cordoes, setCordoes] = useState<Array<Cordao>>([]);
    const [custos, setCustos] = useState<Array<CustoIndireto>>([]);
    const [custoLinha, setCustoLinha] = useState<Array<CustoIndireto>>([]);
    const [estampas, setEstampas] = useState<Array<CustoIndireto>>([]);
    const [fitas, setFitas] = useState<Array<Fita>>([]);
    const [fotolitos, setFotolitos] = useState<Array<Fotolito>>([]);
    const [impostos, setImpostos] = useState<Array<Imposto>>([]);
    const [lucros, setLucros] = useState<Array<Lucro>>([]);
    const [tecidos, setTecidos] = useState<Array<Tecido>>([]);
 
    useEffect( () =>{
        getAllComponent();
    },[]);

    const getAllComponent:() => void =  async () => {
        const { docs:acessorioRepo} = await get(`/acessorio`);
        setAcessorios(acessorioRepo);
        const { docs:costurasRepo} = await get(`/costura`);
        setCosturas(costurasRepo);
        const { docs:cordoesRepo} = await get(`/cordao`);
        setCordoes(cordoesRepo);
        const { docs:custosIndiretosRepo} = await get(`/custo/indireto`);
        setCustos(custosIndiretosRepo);
        const { docs:custosLinhasRepo} = await get(`/custo/linha`);
        setCustoLinha(custosLinhasRepo);
        const { docs:estampasRepo} = await get(`/estampa`);
        setEstampas(estampasRepo);
        const { docs:fitasRepo} = await get(`/fita`);
        setFitas(fitasRepo);
        const { docs:fotolitosRepo} = await get(`/fotolito`);
        setFotolitos(fotolitosRepo);
        const { docs:impostosRepo} = await get(`/imposto`);
        setImpostos(impostosRepo)
        const { docs:lucrosRepo} = await get(`/lucro`);
        setLucros(lucrosRepo)
        const { docs: tecidosRepo} = await get(`/tecido`);
        setTecidos(tecidosRepo)
        
    }


    const cadastra = async event => {
        event.preventDefault();
        orcamento.setcorteVT(corte);
        orcamento.setCliente(cliente);
        console.log(orcamento)
        post('/orcamento', orcamento.getBody()).then( () => browserHistory.push('/consulta-orcamento?msg=cadastrado'));
    }

    
    return (
        <>
            <TitlePage text={'Cadastro Orçamento'} />
            <ComponentForm submit={cadastra} method="post">
                <Card title='Dados do solicitante' className='show' id='collapseOne' >
                    <div className="row">
                        <Input 
                            label='Empresa'
                            id='empresa'
                            class='col-md-3 mb-3'
                            value={cliente.nome}
                            change={cliente.inputChange}
                            required={true}
                        />
                        <Input 
                            label='Solicitante'
                            id='solicitante'
                            class='col-md-3 mb-3'
                            value={cliente.solicitante}
                            change={cliente.inputChange}
                            required={true}
                        />
                        <Input 
                            label='Email'
                            id='email'
                            type='email'
                            class='col-md-3 mb-2'
                            value={cliente.email}
                            change={cliente.inputChange}
                            required={true}
                        />
                        <Input 
                            label='Telefone'
                            id='telefone'
                            type='phone'
                            class='col-md-3 mb-2'
                            value={cliente.telefone}
                            change={cliente.inputChange}
                            required={true}
                        />
                    </div>
                    <div className="row">
                        <Input 
                            label='Logradouro'
                            id='telefone'
                            class='col-md-3 mb-3'
                            value={cliente.endereco?.logradouro}
                            change={cliente.endereco?.inputChange}
                        />
                        <Input 
                            label='Numero'
                            id='numero'
                            class='col-md-3 mb-3'
                            value={cliente.endereco?.numero}
                            change={cliente.endereco?.inputChange}
                        />
                        <Input 
                            label='Bairro'
                            id='bairro'
                            class='col-md-3 mb-2'
                            value={cliente.endereco?.bairro}
                            change={cliente.endereco?.inputChange}
                        />
                        <Input 
                            label='CEP'
                            id='cep'
                            type='phone'
                            class='col-md-3 mb-2'
                            value={cliente.endereco?.cep}
                            change={cliente.endereco?.inputChange}
                            required={true}
                        />
                        <Input 
                            label='Cidade'
                            id='cidade'
                            class='col-md-3 mb-2'
                            value={cliente.endereco?.cidade}
                            change={cliente.endereco?.inputChange}
                        />
                        <SelectEasy className="col-md-3 mb-2"
                            texto={'Estado'}
                            //itemSelecionado={cliente.endereco?.estado}
                            dados={estados}
                            callback={cliente.endereco?.inputChange}
                        />
                        <Input 
                            label='Complemento'
                            id='complemento'
                            class='col-md-3 mb-2'
                            value={cliente.endereco?.complemento}
                            change={cliente.endereco?.inputChange}
                        />
                    </div>
                </Card>
                <Card title='Corte VT' className='hide' id='collapseTwo'>
                    <div className="row">
                        <Input 
                            label='Quantidade'
                            id='quantidade'
                            type='number'
                            class='col-md-3 mb-3'
                            value={corte.quantidade}
                            change={corte.inputChange}
                            required={true}
                        />
                        <Input 
                            label='Altura'
                            id='altura'
                            class='col-md-2 mb-2'
                            value={corte.altura}
                            change={corte.inputChange}
                            required={true}
                        />
                        <Input 
                            label='Sobra da Costura'
                            id='sobra-costura'
                            class='col-md-3 mb-3'
                            value={corte.sobreCostura}
                            change={corte.inputChange}
                            required={true}
                        />
                        <Input 
                            label='Largura'
                            id='largura'
                            class='col-md-2 mb-2'
                            value={corte.largura}
                            change={corte.inputChange}
                            required={true}
                        />
                        <Input 
                            label='Espessura'
                            id='espessura'
                            class='col-md-2 mb-2'
                            value={corte.espessura}
                            change={corte.inputChange}
                            required={true}
                        />
                    </div>
                    <div className="row">

                        <SelectEasy
                            className="col-md-5 mb-3"
                            texto={'Tecido'}
                            firstOption='um tecido'
                            ////itemSelecionado={estampaSelecionada}
                            dados={tecidos}
                            callback={orcamento.inputChange}
                        />
                      
                        <Input 
                            label='Tipo'
                            id='tipo'
                            class='col-md-3 mb-3'
                            value={corte.tipo}
                            change={corte.inputChange}
                            //value='Saco'
                        />

                        
                        <SelectEasy
                            className="col-md-4 mb-3"
                            texto={'Mesa'}
                            firstOption='uma mesa'
                            ////itemSelecionado={mesaSelecionada}
                            //dados={mesas}
                            callback={corte.inputChange}
                        />
                        <Input 
                            label='Margem'
                            id='margem'
                            class='col-md-2 mb-2'
                            value={corte.margem}
                            change={corte.inputChange}
                            required={true}
                        />
                        <Input 
                            label='Corte(VT)'
                            id='corte'
                            class='col-md-2 mb-2'
                            //value={corte.quantidade}
                            change={corte.inputChange}
                            required={true}
                            disabled={true}
                        />
                    </div>
                </Card>
                <Card title='Dados do Orçamento' className='hide' id='collapseThird'>
                    <div className="row">
                        <SelectEasy
                            className="col-md-3 mb-3"
                            texto={'Estampa'}
                            firstOption='uma estampa'
                            ////itemSelecionado={estampaSelecionada}
                            dados={estampas}
                            callback={orcamento.inputChange}
                        />
                        <SelectEasy
                            className="col-md-3 mb-3"
                            texto={'Costura'}
                            firstOption='uma costura'
                            //itemSelecionado={costuraSelecionada}
                            dados={costuras}
                            callback={orcamento.inputChange}
                        />
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Custo Indireto'}
                            firstOption='um custo indireto'
                            //itemSelecionado={custoIndiretoSelecionado}
                            dados={custos}
                            callback={orcamento.inputChange}
                        />
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Custo Linha'}
                            firstOption='um custo de linha'
                            //itemSelecionado={custoLinhaSelecionada}
                            dados={custoLinha}
                            callback={orcamento.inputChange}
                        />
                    </div>
                    <div className="row">
                        <Input 
                            label='Mão de Obra'
                            id='mao-de-obra'
                            class='col-md-3 mb-3'
                            value={orcamento.maoDeObra}
                            change={orcamento.inputChange}
                            required={true}
                        />
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Acessório'}
                            firstOption='um acessório'
                            //itemSelecionado={acessorioSelecionado}
                            dados={acessorios}
                            callback={orcamento.inputChange}
                        />

                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Cordão'}
                            firstOption='um cordão'
                            //itemSelecionado={cordaoSelecionado}
                            dados={cordoes}
                            callback={orcamento.inputChange}
                        />
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Fita'}
                            firstOption='uma fita'
                            //itemSelecionado={fitaSelecionada}
                            dados={fitas}
                            callback={orcamento.inputChange}
                        />
                    </div>
                    <div className="row">
                        <Input 
                            label='Frete'
                            id='frete'
                            class='col-md-3 mb-3'
                            value={orcamento.frete}
                            change={orcamento.inputChange}
                            required={true}
                        />
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Imposto'}
                            firstOption='um imposto'
                            //itemSelecionado={impostoSelecionado}
                            dados={impostos}
                            callback={orcamento.inputChange}
                        />
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Lucro'}
                            firstOption='um lucro'
                            //itemSelecionado={lucroSelecionado}
                            dados={lucros}
                            callback={orcamento.inputChange}
                        />
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Fotolito'}
                            firstOption='um fotolito'
                            //itemSelecionado={fotolitoSelecionado}
                            dados={fotolitos}
                            callback={orcamento.inputChange}
                        />
                    </div>
                </Card>
                <Card title='Resumo' className='hide' id='collapseFourth'>
                    <div className="row">
                        Valor do Corte do tecido: R$ 1.000,00
                    </div>
                    <div className="row">
                        Valor por peça: R$ 1.000,00
                    </div>
                    <div className="row">
                        Valor por total: R$ 1.000,00
                        metragem gasta no tecido
                        tamanho do corte
                        quantidade de peças
                        quantidade de metros gasto em fita e cordao
                    </div>
                </Card>
                <ButtonsSave url={"/consulta-orcamento"}/>
            </ComponentForm>            
        </>
    )
    
}

export default CadastroOrcamento;   