import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { delet, get } from '../../service/api';
import ButtonsConsulta from '../../shared/components/buttons/consulta';
import ButtonsPagination from '../../shared/components/buttons/pagination'
import ModalExcluir from '../../shared/components/modals/modal-excluir';
import MsgErroTable from '../../shared/components/util/msg-erro-table';
import { CurrencyMask } from '../../shared/helper/helperutil';
import HeaderForConsulta from '../../shared/components/util/header-for-consulta';
import Costura from '../../models/Constura';


const ConsultaCostura = (props:Readonly<any>) => {

    const [limitPagination, setLimitPagination] = useState<number>(5);
    const [page, setPage] = useState<Array<Number>>([]);
    const [pag, setPag] = useState<number>(0);
    const [modalExcluir, setModalExcluir] = useState<boolean>(false);
    const [searchElement, setSearchElement] = useState<string>('');
    const [msg, setMsg] = useState('');
    const [quantityItens, setQuantityItens] = useState(0);

    const [list, setList] = useState<Array<Costura>>([]);
    const [idSelected, setIdSelected] = useState<string>('');

    useEffect( () => {
        setMsg(props.location.query.msg || '');
        getList()
    },[]);

    useEffect( () => getList() ,[pag, searchElement, limitPagination]);

    useEffect( () => {
        const newPage:Array<Number> = new Array();
        const maximumPagination = quantityItens / limitPagination;
        for(let item = 0; item < maximumPagination; item++){
            newPage.push(item)
        }
        setPage(newPage);
    } ,[quantityItens, limitPagination]);

    useEffect( () => {
        setPag(0)
        setLimitPagination(5);
        setList([])
        getList();
        setIdSelected(``);
    } ,[msg, searchElement]);

    useEffect( () => {
        getList();
        setIdSelected(``);
        setLimitPagination(5);
        setPag(0)
    } ,[msg]);

    const getList: () => void = async () => {
        const repository = await get(`/costura?limit=${limitPagination}&skip=${limitPagination * pag}&name=${searchElement}`);
        setQuantityItens(repository?.total);
        setList(repository?.docs);
    }

    const deleteItem = async () => {
        await delet(`/costura/${idSelected}`);
        handleClose();
        setMsg('excluído');   
    }
    
    const handleClose = () => setModalExcluir(false);

    const handleShow = (item) => {
        setIdSelected(item);
        setModalExcluir(true);
    }

    const backPage = () => setPag( pag - 1 );

    const nextPage = () => setPag( pag + 1 );

    const search = (e) => setSearchElement(e);
    
    const changePagination = (e) => {
        setLimitPagination(e)
        setPag(0);
    };

    const changePag = (e) => setPag(e);
    
    return (
        <div>
            <HeaderForConsulta
                 component='Costuras'
                 msg={msg}
                 search={search}
                 name={searchElement}
                 changePagination={changePagination}
                 list={list?.length}
                 valueSelect={limitPagination}
            />
            <table className="table table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th></th>
                        <th scope="col">Nome</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Tempo</th>
                        <th scope="col">Tamanho</th>
                        <th scope="col">Descrição</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.length > 0 ? list.map((costura:Costura) =>
                        <tr key={costura._id}>
                            <td>
                                <ButtonsConsulta url={`/alterar-costura?id=${costura._id}`}
                                   click={() => handleShow(costura._id)}
                                />
                            </td>
                            <td>{costura.nome}</td>
                            <td>
                                <CurrencyFormat displayType={'text'}
                                    value={costura.valor} format={CurrencyMask}>
                                </CurrencyFormat>
                            </td>
                            <td>{costura.tempo}</td>
                            <td>
                                {costura?.tamanho?.nome}
                            </td>
                            <td>{costura.descricao}</td>
                        </tr>
                    ) : <MsgErroTable colunas="6" />
                    }
                </tbody>
            </table>
            <ButtonsPagination
                currentElement={ (e) => changePag(e) }
                page={page}
                currentPage={pag}
                nextPage={nextPage}
                backPage={backPage}
            />
            <ModalExcluir 
                texto={`Costura`}
                show={modalExcluir}
                hide={handleClose}
                delete={deleteItem}
            />
        </div>
    )
}

export default ConsultaCostura;