import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import api from '../../service/api';
import { CurrencyMask } from '../../shared/helper/helperutil';
import TitlePage from '../../shared/components/html/title';
import ButtonsUpdate from '../../shared/components/buttons/update';
import Input  from '../../shared/components/html/input';
import InputNumber  from '../../shared/components/html/input-for-number';
import Fita from '../../models/Fita';
import ComponentForm from '../../shared/components/html/component-form';



const AlterarFita = (props:any) =>{
    
    const [fita, setFita] = useState<Fita>(new Fita(null));
    const [msg, setMsg] = useState<string>(``); 
    const [id] = useState<string>(props?.location?.query?.id);

    useEffect(() =>{
        if(id){
            getItem();
        }else{
            setMsg(`A Fita escolhida esta indisponivel no momento!`)
            goTo();
        }
    }, [])

    const getItem = async () => {
        try {
            const { data: repository } = await api.get(`/fita/${id}`)
            setFita(new Fita(repository));
        } catch (err) {
            console.error(err)
        }
    }

    const altera = async event => {
        event.preventDefault();

        const body = {
            "nome": fita.nome,
            "descricao": fita.descricao,
            "largura": fita.largura,
            "valorm2": fita.valorm2,
            "valorRolo": fita.valorRolo,
            "rendimento": fita.rendimento
        }
        try {
            await api.put(`/fita/${id}`, body).then(() => {
                setMsg(`Fita: ${fita.nome} atualizada com sucesso!`);
                goTo();
            })
        } catch (err) {
            setMsg('Houve um problema com o login, verifique seu usuário e senha!' )

        }
    }

    const goTo = () => browserHistory.push(`/consulta-fita?msg=${msg}`)
    
    return (
        <>
            <TitlePage text={'Atualizar fita'} />
            <ComponentForm submit={altera} method="put">
                <div className="row">
                    <Input 
                        value={fita.nome }
                        change={ (e) => {fita.inputChange(e); setMsg(e.target.value)}}
                        class="col-md-12 mb-3"
                        id="nome-fita"
                        placeholder="Nome da fita"
                        label="Nome"
                        required={true}
                    />
                </div>
                <div className="row">
                    <Input 
                        value={fita.descricao }
                        change={ (e) => {fita.inputChange(e); setMsg(e.target.value)}}
                        class="col-md-12 mb-3"
                        id="descricao-fita"
                        placeholder="Descrição da fita"
                        label="Descrição"
                        required={true}
                    />
                </div>
                <div className="row">
                    <Input 
                        format={CurrencyMask}
                        value={ `R$: ${fita.valorm2 ? fita.valorm2 : 0}`}
                        class="col-md-6 mb-3"
                        id="valorm2-fita"
                        placeholder="R$ 0,00"
                        label="Preço m2"
                        disabled={true}
                    />
                    <InputNumber
                        value={fita.valorRolo}
                        onValueChange={(e) => {fita.inputNumberChange(e.floatValue, 'valorRolo'); setMsg(e.value)}}
                        label="Preço do Rolo"
                        class="col-md-6 mb-3"
                        prefix={'R$'}
                        format={CurrencyMask}
                        id="valorRolo-fita"
                        placeholder="R$ 0,00"
                    />
                </div>
                <div className="row">
                    <InputNumber
                        value={fita.largura}
                        onValueChange={(e) => {fita.inputNumberChange(e.floatValue, 'largura'); setMsg(e.value)}}
                        label="Largura"
                        class="col-md-6 mb-3"
                        thousandSeparator={true} 
                        suffix={' m'}
                        id="largura-fita"
                        placeholder="Largura da fita"
                    />
                    <InputNumber
                        value={fita.rendimento}
                        onValueChange={(e) => {fita.inputNumberChange(e.floatValue, 'rendimento'); setMsg(e.value)}}
                        label="Rendimento"
                        class="col-md-6 mb-3"
                        id="rendimento-fita"
                        placeholder="Rendimento da fita"
                    />
                </div>
                <ButtonsUpdate url="fita" />
            </ComponentForm>
        </>
    )
    
}

export default AlterarFita;