import React from 'react';

const Input = (props) =>{
    const disabled = props.disabled ? 'disabled' : '';
    const required = props.required ? 'required' : '';
    return (
    <div className={props.class}>
        <label htmlFor={props.id}>
                {props.label}
        </label>
        <input 
            onClick={props.click}
            name={props.id}
            value={props.value}
            defaultValue={ props.dValue}
            defaultChecked = { props.defaultChecked }
            ref={props.func} 
            onChange={props.change}
            type={props.type ? props.type : 'text'} 
            className={ props.classInput ? props.classInput : "form-control" }
            id={props.id}
            placeholder={props.placeholder ? props.placeholder : props.label}
            required={required}
            disabled={disabled}
        /> 
        {props.children}
    </div>
);
}
export default React.memo(Input);