export const CurrencyMask = (valor) => {
    if (valor <= 0) return
    const step1 = valor + '';
    const step2:any = step1.replace(/\D/g, '');
    const step3 = (step2 / 100).toFixed(2) + '';
    const step4 = step3.replace(".", ",")
        .replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,")
        .replace(/(\d)(\d{3}),/g, "$1.$2,");
    return 'R$ ' + step4;
}

export const CurrencyMaskForTable = (valor) => {
    if (valor <= 0) return
    const step1:any = valor + '';
    let step3
    const step2 = step1.replace(/\D/g, '');
    if (step1.startsWith('0.') || !step1.includes('.')) {
        step3 = (step1 / 1).toFixed(2) + ''
    } else {
        step3 = (step2.includes('.') || step2.startsWith('00')) ? (step1 / 100).toFixed(2) + '' : (step1 / 1).toFixed(2) + '' ;   
    }
    const step4 = step3.replace(".", ",")
        .replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,")
        .replace(/(\d)(\d{3}),/g, "$1.$2,");
    return 'R$ ' + step4;
}


export const PercentMask = (valor) => {
    if (valor <= 0) return
    const step1 = valor + '';
    const step2:any = step1.replace(/\D/g, '');
    const step3 = (step2 / 100).toFixed(2) + '';
    const step4 = step3.replace(".", ",")
        .replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,")
        .replace(/(\d)(\d{3}),/g, "$1.$2,");
    return step4 + '%';
}

export const FormatPercentForBackEnd = (valor) => {
    if (!valor) return 0
    if ('number' == typeof valor) return valor;
    const step1 = valor.replace(/[A-Za-z-$-%]/g, '')
    const step2 = step1.replace('.', '').replace(',', '.');
    return Number(step2);
}

export const FormatCurrencyForBackEnd = (valor) => {
    if (!valor) return 0
    if ('number' == typeof valor) return valor;
    return Number(valor.replace(/\D/g, ''));
}

export const StringForNumber = value =>{
    if (!value) return 0;
    return isNaN(value) ? Number(value.replace(/\D/g, '')) : Number(value);
};
