 import { ChangeEvent } from 'react';
import { CurrencyMaskForTable, StringForNumber } from '../shared/helper/helperutil';

class Cordao{
    readonly _id?:string;
    private _nome?:string;
    private _descricao?:string;
    private _tipo?:string;
    private _rendimento?:string;
    private _espessura?:number;
    private _custo?:number;

    constructor( repository: any){
        this._id = repository?._id;
        this._nome = repository?.nome;
        this._descricao = repository?.descricao;
        this._tipo = repository?.tipo;
        this._rendimento = repository?.rendimento;
        this._espessura = repository?.espessura;
        this._custo = repository?.custo;
    }

    get nome(){
        return this._nome;
    }

    get descricao(){
        return this._descricao;
    }

    get tipo(){
        return this._tipo;
    }

    get rendimento(){
        return this._rendimento;
    }

    get espessura () {
        return StringForNumber(this._espessura);
    }

    get custo(){
        return this._custo;
    }

    get custoForTable () {
        return CurrencyMaskForTable(this._custo);
    }

 
    inputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const element = e.target;
        const name = `_${element.name}`;
        if(name === '_custo'){
           this._custo = this.getNumber(element);
        }else if(name === '_espessura'){
            this._espessura = this.getNumber(element);
        } else{
            this[name] = element.value;
        }
    }

    private getNumber (element):number{
        return isNaN(Number(element.value)) ? Number(element.value.replace(/\D/g, '')) : Number(element.value);
    }
}

export default Cordao;