import React from 'react';
import { Link } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faList } from '@fortawesome/free-solid-svg-icons';


const Funcionalidades = (props) => (
    <li className="nav-item dropdown">
        <button className="nav-link dropdown-toggle meuButao" id="navbarDropdown" data-toggle="dropdown">
            {props.span}
        </button>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to={"/consulta" + props.navegacao}>
                <FontAwesomeIcon icon={faList} size="1x" />
                {" Consultar"}
            </Link>
            <Link className="dropdown-item" to={"/cadastro" + props.navegacao}>
                <FontAwesomeIcon icon={faPlus} size="1x" />
                {" Adicionar"}
            </Link>
        </div>
    </li>
);
export default Funcionalidades;

