import React, { Component, useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import api from '../../service/api';
import { FormatPercentForBackEnd, PercentMask } from '../../shared/helper/helperutil';
import ButtonsUpdate from '../../shared/components/buttons/update';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import Imposto from '../../models/Imposto';
import ComponentForm from '../../shared/components/html/component-form';


const AlterarImposto  = (props:any) => {

    const [imposto, setImposto] = useState<Imposto>(new Imposto(null));
    const [msg, setMsg] = useState<string>(``); 
    const [id] = useState<string>(props?.location?.query?.id);

    useEffect(() =>{
        if(id){
            getItem();
        }else{
            setMsg(`Imposto escolhido esta indisponivel no momento!`)
            goTo();
        }
    }, [])

    const getItem = async () => {
        try {
            const { data: repository } = await api.get(`/imposto/${id}`);
            setImposto(new Imposto(repository));
        } catch (err) {
            console.error(err)
        }
    }

    const altera = async event => {
        event.preventDefault();
        try {
            await api.put(`/imposto/${id}`,
                {
                    "nome": imposto.nome,
                    "descricao": imposto.descricao,
                    "valor": imposto.valor
                }
            ).then(() => {
                setMsg(`Imposto ${imposto.nome} atualizado com sucesso!`);
                goTo();
            })
            
        } catch (err) {
            setMsg( 'Houve um problema com o login, verifique seu usuário e senha!');

        }
    }

    const goTo  = () => browserHistory.push(`/consulta-imposto?msg=${msg}`);

    return (
        <div>
            <TitlePage text={'Atualizar imposto'} />
            <ComponentForm submit={altera} method="put"> 
                <div className="row">
                    <Input 
                        value={imposto.nome || ``}
                        change={ (e) => {imposto.inputChange(e); setMsg(e.target.value)}}
                        class="col-md-6 mb-3"
                        id="nome-imposto"
                        placeholder="Nome do imposto"
                        label="Nome"
                        required={true}
                    />
                    <InputNumber
                        value={imposto.valor || 0}
                        onChange={ (e) => {imposto.inputChange(e); setMsg(e.target.value)}}
                        label="Valor"
                        class="col-md-6 mb-3"
                        thousandSeparator={true} 
                        suffix={' %'}
                        format={PercentMask}
                        id="valor-imposto"
                        placeholder="0 %"
                    />
                </div>
                <div className="row">
                    <Input 
                        value={imposto.descricao || ``}
                        change={ (e) => {imposto.inputChange(e); setMsg(e.target.value)}}
                        class="col-md-12 mb-3"
                        id="descricao-imposto"
                        placeholder="Descrição do imposto"
                        label="Descriçao"
                        required={true}
                    />
                </div>
                <ButtonsUpdate url={'imposto'} />
            </ComponentForm>
        </div>
    )
    
}

export default AlterarImposto;