import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const ModalUpdate = (props) => (
    <Modal show={props.show} onHide={props.hide}>
        <Modal.Header closeButton>
            <Modal.Title>Deseja atualizar o status de {props.texto}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <select className="form-control"
                onChange={(event) => props.status(event.target.value)}>
                <option value="Rascunho" >Rascunho</option>
                <option value="Enviado">Enviado</option>
                <option value="Amostra virtual">Amostra virtual</option>
                <option value="Amostra física">Amostra física</option>
                <option value="Aprovado">Aprovado</option>
                <option value="Pendente de Pagamento">Pendente de Pagamento</option>
                <option value="Concluído">Concluído</option>
                <option value="Negado">Negado</option>
            </select>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.hide}>
                Cancelar
                </Button>
            <Button variant="success" onClick={props.callback}>
                Atualizar {props.texto}
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ModalUpdate;