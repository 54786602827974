import React, { useState } from 'react';
import { post } from '../../service/api';
import ButtonsSave from '../../shared/components/buttons/save';
import { browserHistory } from 'react-router';
import { CurrencyMask, FormatCurrencyForBackEnd, StringForNumber } from '../../shared/helper/helperutil';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import Fita from '../../models/Fita';
import ComponentForm from '../../shared/components/html/component-form';

const CadastroFita = () => {

    const[fita] = useState<Fita>(new Fita(null));
    const[msg, setMsg] = useState<string>('');


    const cadastra = async event => {
        event.preventDefault();
        console.log(fita)
        const body = {
            "nome": fita.nome,
            "descricao": fita.descricao,
            "largura": fita.largura,
            "valorm2": fita.valorm2,
            "valorRolo": fita.valorRolo,
            "rendimento": fita.rendimento
        }
        post('/fita', body).then(() => browserHistory.push('/consulta-fita?msg=cadastrada'));
    }

    return (
        <>
            <TitlePage text={'Nova Fita'} />
            <ComponentForm submit={cadastra} method="post">
                <div className="row">
                    <Input 
                        class="col-md-12 mb-3"
                        id="nome-fita"
                        placeholder="Nome da fita"
                        change={fita.inputChange}
                        label="Nome"
                        required={true}
                    />
                </div>
                <div className="row">
                    <Input 
                        class="col-md-12 mb-3"
                        id="descricao-fita"
                        placeholder="Descrição da fita"
                        change={fita.inputChange}
                        label="Descrição"
                        required={true}
                    />
                </div>
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="valorMetro2-fita"
                        placeholder="R$ 0,00"
                        format={CurrencyMask}
                        value={ `R$: ${fita.valorm2 ? fita.valorm2 : 0}`}
                        label="Preço m2"
                        disabled={true}
                    />
                    <InputNumber
                        label="Preço do Rolo"
                        class="col-md-6 mb-3"
                        prefix={'R$'}
                        format={CurrencyMask}
                        onValueChange={(e) => {fita.inputNumberChange(e.floatValue, 'valorRolo'); setMsg(e.value)}}
                        id="valorRolo-fita"
                        placeholder="R$ 0,00"
                    />
                </div>
                <div className="row">
                    <InputNumber
                        label="Largura"
                        class="col-md-6 mb-3"
                        thousandSeparator={true} 
                        suffix={' m'}
                        onValueChange={(e) => {fita.inputNumberChange(e.value, 'largura'); setMsg(e.value)}}
                        id="largura-fita"
                        placeholder="Largura da fita"
                    />
                    <InputNumber
                        label="Rendimento"
                        class="col-md-6 mb-3"
                        onValueChange={(e) => {fita.inputNumberChange(e.value, 'rendimento'); setMsg(e.value)}}
                        id="rendimento-fita"
                        placeholder="Rendimento da fita"
                    />
                </div>
                <ButtonsSave url={"/consulta-fita"} />
            </ComponentForm>
        </>
    );
}

export default CadastroFita;