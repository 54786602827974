import React, { useState } from 'react';
import { post } from '../../service/api';
import ButtonsSave from '../../shared/components/buttons/save';
import { browserHistory } from 'react-router';
import { CurrencyMask } from '../../shared/helper/helperutil';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import Fotolito from '../../models/Fotolito';
import ComponentForm from '../../shared/components/html/component-form';

const FotolitoLinha = () => {

    const [fotolito] = useState<Fotolito>(new Fotolito(null)); 

    const cadastra = async event => {
        event.preventDefault();
        const body = {
            "nome": fotolito.nome,
            "quantidade": fotolito.quantidade,
            "valor": fotolito.valor,
        };
        post('/fotolito', body).then(() => browserHistory.push('/consulta-fotolito?msg=cadastrado'));
    }

    return (
        <>
            <TitlePage text={'Novo Fotolito'} />
            <ComponentForm submit={cadastra} method="post"> 
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        placeholder="Nome do fotolito"
                        id="nome-fotolito"
                        value={fotolito.nome}
                        change={fotolito.inputChange}
                        label="Fotolito"
                        required={true}
                    />
                    <InputNumber
                        label="Preço"
                        class="col-md-6 mb-3"
                        prefix={'R$'}
                        format={CurrencyMask}
                        id="valor-fotolito"
                        value={fotolito.valor}
                        onChange={fotolito.inputChange}
                        placeholder="R$ 0,00"
                    />
                </div>
                <div className="row">
                    <Input 
                        class="col-md-12 mb-3"
                        placeholder="Quantidade"
                        id="quantidade-fotolito"
                        value={fotolito.quantidade}
                        change={fotolito.inputChange}
                        label="Quantidade"
                        required={true}
                        type={'number'}
                    />
                </div>
                <ButtonsSave url={"/consulta-fotolito"} />
            </ComponentForm>  
        </>
    );
}
export default FotolitoLinha;