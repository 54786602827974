import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

 const ModalExcluir = (props) => (
    <Modal show={props.show} onHide={props.hide}>
        <Modal.Header closeButton>
            <Modal.Title>Deseja excluir o {props.texto}?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.hide}>
                Cancelar
            </Button>
            <Button variant="primary" onClick={props.delete}>
                Excluir {props.texto}
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ModalExcluir;