import React, { useState } from 'react';
import { post } from '../../service/api';
import ButtonsSave from '../../shared/components/buttons/save';
import { browserHistory } from 'react-router';
import { CurrencyMask } from '../../shared/helper/helperutil';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import ComponentForm from '../../shared/components/html/component-form';
import Tecido from '../../models/Tecido';


const CadastroTecidos = () => {

    const [tecido] = useState<Tecido>(new Tecido(null));

    const cadastra = async event => {
        event.preventDefault();
        const body = {
            "nome": tecido.nome,
            "descricao": tecido.descricao,
            "valorM2": tecido.valorM2,
            "largura": tecido.largura,
            "gramatura": tecido.gramatura,
            "sobra": tecido.sobra
        };
        console.log(body)
        post('/tecido', body).then(() => browserHistory.push('/consulta-tecido?msg=cadastrado'));
    }
    
    return (
        <>
            <TitlePage text="Novo Tecido" />
            <ComponentForm submit={cadastra} method="post">
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="nome-tecido"
                        placeholder="Nome do tecido"
                        value={tecido.nome}
                        change={tecido.inputChange}
                        label="Nome"
                        required={true}
                    />
                    <Input 
                        class="col-md-6 mb-3"
                        id="descricao-tecido"
                        placeholder="Descrição do tecido"
                        value={tecido.descricao}
                        change={tecido.inputChange}
                        label="Descrição"
                        required={true}
                    />
                </div>
                <div className="row">
                    <InputNumber
                            label="Valor m2"
                            class="col-md-3 mb-3"
                            prefix={'R$'}
                            format={CurrencyMask}
                            value={tecido.valorM2}
                            onChange={tecido.inputChange}
                            id="valorMetro2"
                            placeholder="R$ 0,00"
                    />
                    <InputNumber
                        thousandSeparator={true}
                        label="Largura"
                        class="col-md-3 mb-3"
                        suffix={' m'}
                        value={tecido.largura}
                        onChange={tecido.inputChange}
                        id="largura"
                        placeholder="Largura"
                    />
                    <Input 
                        class="col-md-3 mb-3"
                        id="gramatura"
                        placeholder="Gramatura"
                        value={tecido.gramatura}
                        change={tecido.inputChange}
                        label="Gramatura"
                        required={true}
                    />
                    <Input 
                        class="col-md-3 mb-3"
                        id="sobra"
                        placeholder="Sobra"
                        value={tecido.sobra}
                        change={tecido.inputChange}
                        label="Sobra"
                        required={true}
                        type={'number'}
                    />
                </div>
                <ButtonsSave url={"/consulta-tecido"}/>
            </ComponentForm>
        </>
    );
}
export default CadastroTecidos;