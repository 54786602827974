import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import api from '../../service/api';
import { CurrencyMask } from '../../shared/helper/helperutil';
import ButtonsAlter from '../../shared/components/buttons/update';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import Costura from '../../models/Constura';
import Select from '../../shared/components/html/select';
import { Tamanho } from '../../models/Tamanho';
import ComponentForm from '../../shared/components/html/component-form';

const AlterarCostura  = (props:Readonly<any>) => {

    const [id] = useState<string>(props?.location?.query?.id);
    const [costura, setCostura] = useState<Costura>(new Costura(null));
    const [tamanhos, setTamanhos] = useState<Array<Tamanho>>([]);
    const [msg, setMsg] = useState('');


    useEffect( () => {
        if (id) {
            getItem()
        }else{
            goTo(`/consulta-costura`);
        }
    }, [])

    const getItem = async () => {
        try {
            const { data: repository } = await api.get(`/costura/${id}`);
            const { data: tamanhosRepo } = await api.get(`/tamanho`);
            setTamanhos(tamanhosRepo.docs);
            setCostura(new Costura(repository));
        } catch (err) {
            console.error(err)
        }
    }

    const altera = async event => {
        event.preventDefault();
        try {
            await api.put(`/costura/${id}`,
                {
                    "nome": costura.nome,
                    "descricao": costura.descricao,
                    "valor": costura.valor,
                    "tempo": costura.tempo,
                    "tamanho": costura.tamanhoForPayload
                }
            ).then(() => setMsg(`Costura ${costura.nome} atualizada com sucesso!`))
            .then( () => goTo(`/consulta-costura?msg=${msg}`))
        } catch (err) {
            setMsg('Houve um problema com o login, verifique seu usuário e senha!');

        }
    }

    const goTo = (url:String) => browserHistory.push(url);
    
    return (
        <>
            <TitlePage text={'Atualizar Costura'} />
            <ComponentForm submit={altera} method="put">
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="nome-costura"
                        placeholder="Nome da costura"
                        value={costura.nome}
                        change={(e) => {costura.inputChange(e); setMsg(e.target.value)}}
                        label="Nome"
                        required={true}
                    />
                    <InputNumber
                        label="Valor"
                        class="col-md-6 mb-3"
                        prefix={'R$'}
                        format={CurrencyMask}
                        value={CurrencyMask(costura.valor)}
                        onChange={(e) => {costura.inputChange(e); setMsg(e.target.value)}}
                        id="valor-costura"
                        placeholder="R$ 0,00"
                    />
                </div>
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="tempo-costura"
                        placeholder="Tempo da costura"
                        value={costura.tempo}
                        change={(e) => {costura.inputChange(e); setMsg(e.target.value)}}
                        label="Tempo"
                        required={true}
                    />
                    <Select
                            class="col-md-6 mb-3"
                            id="tamanho-costura"
                            label="Tamanho da Costura"
                            value={costura.tamanho}
                            required={true}
                            change={(e) => {costura.tamanhoSelected(e, tamanhos); setMsg(e.target.value) }}
                        >   
                            { 
                                tamanhos?.map((tamanho:Tamanho) => 
                                    <option 
                                        key={tamanho?._id}
                                        value={tamanho?._id}
                                        defaultValue={tamanho._id}
                                    >
                                        {tamanho.nome}
                                    </option> 
                                )
                            }
                    </Select>
                </div>
                <div className="row">
                    <Input 
                        class="col-md-12 mb-3"
                        id="descricao-costura"
                        placeholder="Descrição do Costura"
                        value={costura.descricao}
                        change={(e) => {costura.inputChange(e); setMsg(e.target.value)}}
                        label="Descrição"
                        required={true}
                    />
                </div>
                <ButtonsAlter url={"costura"}/>
            </ComponentForm>
        </>
    )
    
}

export default AlterarCostura;