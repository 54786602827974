import { ChangeEvent } from "react";
import Tecido from "../Tecido";

class CorteVT{
    readonly _id:string;
    private _tecido:Tecido;
    private _tipo:String;
    private _quantidade:number;
    private _largura:number;
    private _altura:number;
    private _barra:number;
    private _espessura:number;
    private _multiplicador:number;
    private _pecaExtra:number;
    private _valorTotal:number;
    private _margem:number;
    private _sobraCostura:number;

    constructor(repository:any){
        this._id = repository?.id;
        this._quantidade = repository?.quantidade;
        this._largura = repository?.largura;
        this._altura = repository?.altura;
        this._barra = repository?.barra;
        this._espessura = repository?.espessura;
        this._tecido = repository?.tecido;
        this._tipo = repository?.tipo;
        this._multiplicador = repository?.multiplicador;
        this._pecaExtra = repository?.pecaExtra;
        this._valorTotal = repository?.valorTotal;
        this._margem = repository?.margem;
        this._sobraCostura = repository?.sobraCostura;
    }

    get tecido(){ return this._tecido;}

    get tipo(){ return this._tipo;}

    get quantidade(){ return this._quantidade;}

    get largura(){ return this._largura;}

    get altura(){ return this._altura;}

    get barra(){ return this._barra}

    get espessura(){ return this._espessura;}

    get multiplicador(){return this._multiplicador;}

    get pecaExtra(){ return this._pecaExtra;}

    get valorTotal(){ return this._valorTotal;}

    get margem() {return this._margem;}

    get sobreCostura(){ return this._sobraCostura;}

    inputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const element = e.target;
        const name = `_${element.name.replace('-orcamento', '')}`;
        if(name === '_tecido' || name === '_tipo' ){
            if(name === '_tecido'){
                this._tecido = new Tecido(element.value);
            }else{
                this._tipo = element.value;
            }
        } else{
            this[name] = isNaN(Number(element.value)) ? Number(element.value.replace(/\D/g, '')) : Number(element.value);
        }
    }

    get margemErro(){
        const margemErro = Math.ceil((this.quantidade * Number(this.margem)) / 100);
        return margemErro;
    }

    get quantidadeReal(){
        const quantidadeReal = this.quantidade + this.margemErro;
        return quantidadeReal;
    }

}
export default CorteVT;