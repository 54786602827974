import React from 'react'

const MsgErrorTable = (props) => (
    <tr className="text-center" >
        <td colSpan={props.colunas}>{props.msg ? props.msg : "Não há elementos"}</td>
    </tr>
);
export default MsgErrorTable;
    



