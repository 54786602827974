import React, { useState } from 'react';
import { post } from '../../service/api';
import  ButtonsSave from '../../shared/components/buttons/save';
import { browserHistory } from 'react-router';
import { CurrencyMask, FormatCurrencyForBackEnd } from '../../shared/helper/helperutil';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import ComponentForm from '../../shared/components/html/component-form';
import Estampa from '../../models/Estampa';


const CadastroEstampa = () => {

   const [estampa] = useState<Estampa>(new Estampa(null))

    const cadastra = async event => {
        event.preventDefault();
        const body = {
            "nome": estampa.nome,
            "descricao": estampa.descricao,
            "valor": estampa.valor,
            "silk": estampa.silk,
            "cores": estampa.cores
        };
        post('/estampa', body).then(() => browserHistory.push('/consulta-estampa?msg=cadastrada'));
    }

    return (
        <>
            <TitlePage text={'Nova Estampa'} />
            <ComponentForm submit={cadastra} method="post">
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="nome-estampa"
                        placeholder="Nome da estampa"
                        value={estampa.nome}
                        change={estampa.inputChange}
                        label="Nome"
                        required={true}
                    />
                    <InputNumber
                        label="Preço da estampa"
                        class="col-md-6 mb-3"
                        prefix={'R$'}
                        format={CurrencyMask}
                        value={estampa.valor}
                        onChange={estampa.inputChange}
                        id="valor-estampa"
                        placeholder="R$ 0,00"
                    /> 
                </div>
                <div className="row">
                    <Input
                        type="checkbox" 
                        class="col-md- mb-6"
                        id="silk"
                        value={true}
                        change={estampa.inputChange}
                        label="Silkscreen"
                        required={false}
                    />
                    <Input
                        type="number" 
                        class="col-md-3 mb-6"
                        id="cores"
                        placeholder="Quantidade de cores"
                        value={estampa.cores}
                        change={estampa.inputChange}
                        label="Quantidade de cores"
                        required={false}
                    />
                </div>
                <div className="row">
                    <Input
                        class="col-md-12 mb-3"
                        id="descricao-estampa"
                        placeholder="Descrição da estampa"
                        value={estampa.descricao}
                        change={estampa.inputChange}
                        label="Descrição da estampa"
                        required={true}
                    />
                </div>
                <ButtonsSave url={"/consulta-estampa"} />
            </ComponentForm>
        </>
    )
    
}

export default CadastroEstampa;