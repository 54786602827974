import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import api from '../../service/api';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import ButtonsUpdate from '../../shared/components/buttons/update';
import { Tamanho } from '../../models/Tamanho';
import ComponentForm from '../../shared/components/html/component-form';

export default (props: Readonly<any>) => {

    const [tamanho, setTamanho] = useState<Tamanho>(new Tamanho(null));
    const [msg, setMsg] = useState('');
    const [id] = useState<string>(props?.location?.query?.id)

    useEffect(() => {
        if (id) {
            getItem();
        }else{
            goTo();
        }
    }, []);


    const getItem = async () => {
        try {
            const { data: repository } = await api.get(`/tamanho/${id}`);
            setTamanho(new Tamanho(repository) );
            console.log(tamanho)
        } catch (err) {
            console.error(err)
        }
    }

    const altera = async event => {
        event.preventDefault();
        const body =  {
            "nome": tamanho.nome,
            "minimoLargura": tamanho.minimoLargura,
            "minimoAltura": tamanho.minimoAltura,
            "maximoLargura": tamanho.maximoLargura,
            "maximoAltura": tamanho.maximoAltura
        };
        try {
            setMsg(`Tamanho: ${tamanho.nome} atualizado!`)
            await api.put(`/tamanho/${tamanho._id}`,body).then(goTo())
        } catch (err) {
            setMsg('Houve um problema com o login, verifique seu usuário e senha!')

        }
    }

    const goTo = () => browserHistory.push( `/consulta-tamanho${msg ? `?msg=${msg}` : ''}`);


    return (
        <div>
            <TitlePage text={'Atualizar tamanho'} />
            <ComponentForm submit={altera} method="put">
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="nome"
                        placeholder="Tamanho"
                        value={tamanho.nome || ''}
                        change={(e) => {tamanho.inputChange(e); setMsg(e.target.value)}}
                        label="Nome"
                    />
                </div>
                <div className="row">
                    <Input 
                        class="col-md-12 mb-3"
                        value={tamanho.maximoAltura || 0}
                        change={(e) => {tamanho.inputChange(e); setMsg(e.target.value)}}
                        label="Altura Maxima"
                        type="number" 
                        classInput="form-control col-md-3 mb-3"
                        id="maximoAltura" 
                        placeholder="Altura Max" 
                        required 
                    />
                    <Input 
                        class="col-md-12 mb-3"
                        label="Altura Minima"
                        value={tamanho.minimoAltura || 0} 
                        change={(e) => {tamanho.inputChange(e); setMsg(e.target.value)}}
                        type="number" 
                        classInput="form-control col-md-3 mb-3"
                        id="minimoAltura" 
                        placeholder="Altura Min" 
                        required 
                    />
                    <Input 
                        class="col-md-12 mb-3"
                        id="maximoLargura"
                        placeholder="Largura Max"
                        label="Largura Maxima"
                        type="number"
                        classInput="form-control col-md-3 mb-3"
                        value={tamanho.maximoLargura || 0}
                        change={(e) => {tamanho.inputChange(e); setMsg(e.target.value)}}
                    />
                    <Input 
                        class="col-md-12 mb-3"
                        id="minimoLargura"
                        placeholder="Largura Minima"
                        label="Largura Minimo"
                        type="number"
                        classInput="form-control col-md-3 mb-3"
                        value={tamanho.minimoLargura || 0}
                        change={(e) => {tamanho.inputChange(e); setMsg(e.target.value)}}
                    /> 
                </div>
                <ButtonsUpdate url={'tamanho'} />
            </ComponentForm>
        </div>
    )
}