import React from 'react';
import { Link } from 'react-router';

const ButtonsSave =  (props) => (
    <div className="row mt-3 mb-3">
        <div className="col-md-2 order-md-2">
            <button className="btn btn-success btn-lg btn-block" type="submit">Salvar</button>
        </div>
        <div className="col-md-2 order-md-2">
            <Link to={props.url}>
                <button className="btn btn-info btn-lg btn-block" type="submit">
                    Voltar
                </button>
            </Link>
        </div>
    </div>
);

export default React.memo(ButtonsSave);