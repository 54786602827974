import { ChangeEvent } from "react";
import Endereco from "./Endereco";

class Cliente{
    readonly _id:string;
    private _nome?:string;
    private _solicitante?:string;
    private _email?:string;
    private _telefone?:string;
    private _endereco?:Endereco;

    constructor(repository:any){
        this._id = repository?._id;
        this._nome = repository?.nome;
        this._solicitante = repository?.solicitante;
        this._email = repository?.email;
        this._telefone = repository?.telefone
        this._endereco = repository?.endereco;
    }

    get nome(){
        return this._nome;
    }

    get solicitante(){
        return this._solicitante;
    }

    get email(){
        return this._email;
    }

    get telefone(){
        return this._telefone;
    }

    get endereco(){
        return this._endereco;
    }


    inputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const element = e.target;
        const name = `_${element.name.replace('-orcamento', '')}`;
        this[name] = element.value;
    }


}

export default Cliente;