import React, {useState, useEffect} from 'react';
import { browserHistory } from 'react-router';
import api from '../../service/api';
import { CurrencyMask } from '../../shared/helper/helperutil';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import ButtonsUpdate from '../../shared/components/buttons/update';
import Tecido from '../../models/Tecido';
import ComponentForm from '../../shared/components/html/component-form';

const AlterarTecido = (props: Readonly<any>) =>{

    const [tecido, setTecido] = useState<Tecido>(new Tecido(null));
    const [msg, setMsg] = useState('');
    const [id] = useState<string>(props?.location?.query?.id)

    useEffect(() => {
        if (id) {
            getItem();
        }else{
            goTo();
        }
    }, []);

    const getItem = async () => {
        try {
            console.log(tecido);
            const { data: repository } = await api.get(`/tecido/${id}`)
            setTecido(new Tecido(repository))
            console.log(tecido);
        } catch (err) {
            console.error(err)
        }
    }

    const altera = async event => {
        event.preventDefault();
        try {
            const body = {
                "nome": tecido.nome,
                "descricao": tecido.descricao,
                "valorM2": tecido.valorM2,
                "largura": tecido.largura,
                "gramatura": tecido.gramatura,
                "sobra": tecido.sobra
            }
            await api.put(`/tecido/${id}`, body).then(() => {
                setMsg(`Tecido atualizado com sucesso!`);
                goTo();
            });
            
        } catch (err) {
            setMsg('Houve um problema com o login, verifique seu usuário e senha!');
        }
    }

    const goTo = () => browserHistory.push(`/consulta-tecido?msg=${msg}`);

    return (
        <>
            <TitlePage text="Atualizar Tecido" />
            <ComponentForm submit={altera} method="put"> 
                <div className="row">
                    <Input 
                        value={tecido.nome || ``}
                        change={ (e) => {tecido.inputChange(e); setMsg(e.target.value)}}
                        class="col-md-6 mb-3"
                        id="nome-tecido"
                        placeholder="Nome do tecido"
                        label="Nome"
                        required={true}
                    />
                    <Input 
                        value={tecido.descricao || ``}
                        change={ (e) => {tecido.inputChange(e); setMsg(e.target.value)}}
                        class="col-md-6 mb-3"
                        id="descricao-tecido"
                        placeholder="Descrição do tecido"
                        label="Descrição"
                        required={true}
                    />
                </div>
                <div className="row">
                    <InputNumber
                        label="Valor m2"
                        class="col-md-3 mb-3"
                        prefix={'R$'}
                        format={CurrencyMask}
                        id="valorMetro2-tecido"
                        placeholder="R$ 0,00"
                        value={tecido.valorM2 || ``}
                        onChange={ (e) => {tecido.inputChange(e); setMsg(e.target.value)}}
                    />
                    <InputNumber
                        thousandSeparator={true}
                        label="Largura"
                        class="col-md-3 mb-3"
                        suffix={' m'}
                        id="largura"
                        placeholder="Largura"
                        value={tecido.largura || ``}
                        onChange={ (e) => {tecido.inputChange(e); setMsg(e.target.value)}}
                    />
                    <Input 
                        value={tecido.gramatura || ``}
                        change={ (e) => {tecido.inputChange(e); setMsg(e.target.value)}}
                        class="col-md-3 mb-3"
                        id="gramatura"
                        placeholder="Gramatura"
                        label="Gramatura"
                        type="number"
                    />
                    <Input 
                        value={tecido.sobra || ``}
                        change={ (e) => {tecido.inputChange(e); setMsg(e.target.value)}}
                        class="col-md-3 mb-3"
                        id="sobra"
                        placeholder="Sobra"
                        label="Sobra"
                        type="number"
                    />
                </div>
                <ButtonsUpdate url={'tecido'} />
            </ComponentForm>
        </>
    );
}
export default AlterarTecido;