import React, {  useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import api from '../../service/api';
import { CurrencyMask, CurrencyMaskForTable, FormatCurrencyForBackEnd } from '../../shared/helper/helperutil';
import ButtonsUpdate from '../../shared/components/buttons/update';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import ComponentForm from '../../shared/components/html/component-form';
import Fotolito from '../../models/Fotolito';

const AlterarFotolito = (props: any) => {

    const [fotolito, setFotolito] = useState<Fotolito>(new Fotolito(null));
    const [msg, setMsg] = useState<string>(``); 
    const [id] = useState<string>(props?.location?.query?.id);

    useEffect(() =>{
        if(id){
            getItem();
        }else{
            setMsg(`A Fita escolhida esta indisponivel no momento!`)
            goTo();
        }
    }, [])

    const getItem = async () => {
        try {
            const { data: repository } = await api.get(`/fotolito/${id}`)
            setFotolito(new Fotolito(repository));
        } catch (err) {
            console.error(err)
        }
    }

    const altera = async event => {
        event.preventDefault();
        try {
            await api.put(`/fotolito/${id}`,
                {
                    "nome": fotolito.nome,
                    "quantidade": fotolito.quantidade,
                    "valor": fotolito.valor
                }
            ).then(() => {
                setMsg(`Fotolito: ${fotolito.nome} atualizado com sucesso!`)
                goTo();
            })
            
        } catch (err) {
            setMsg('Houve um problema com o login, verifique seu usuário e senha!');

        }
    }

    const goTo = () => browserHistory.push(`/consulta-fotolito?msg=${msg}`);

    return (
        <>
            <TitlePage text={'Atualizar Fotolito'} />
            <ComponentForm submit={altera} method="put"> 
                <div className="row">
                    <Input 
                        value={fotolito.nome }
                        change={ (e) => {fotolito.inputChange(e); setMsg(e.target.value)}}
                        class="col-md-6 mb-3"
                        id="nome-fotolito"
                        placeholder="Nome do fotolito"
                        label="Fotolito"
                        required={true}
                    />
                </div>
                <div className="row">
                    <InputNumber
                        value={fotolito.valor }
                        onChange={ (e) => {fotolito.inputChange(e); setMsg(e.target.value)}}
                        label="Preço"
                        class="col-md-6 mb-3"
                        prefix={'R$'}
                        format={CurrencyMask}
                        id="valor-fotolito"
                        placeholder="R$ 0,00"
                    />
                </div>
                <div className="row">
                    <Input 
                        value={fotolito.quantidade }
                        change={ (e) => {fotolito.inputChange(e); setMsg(e.target.value)}}
                        class="col-md-12 mb-3"
                        id="quantidade-fotolito"
                        placeholder="Quantidade"
                        label="Quantidade"
                        required={true}
                        type={'number'}
                    />
                </div>
                <ButtonsUpdate url="fotolito" />
            </ComponentForm>
        </>
    );
}

export default AlterarFotolito;