import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { delet, get } from '../../service/api';
import ButtonsPagination from '../../shared/components/buttons/pagination';
import ButtonsConsulta from '../../shared/components/buttons/consulta';
import ModalExcluir from '../../shared/components/modals/modal-excluir';
import MsgErroTable from '../../shared/components/util/msg-erro-table';
import { CurrencyMask, CurrencyMaskForTable } from '../../shared/helper/helperutil';
import HeaderForConsulta from '../../shared/components/util/header-for-consulta';
import Fita from '../../models/Fita';

const ConsultaFita = (props) => {

    const [limitPagination, setLimitPagination] = useState<number>(5);
    const [page, setPage] = useState<Array<Number>>([]);
    const [pag, setPag] = useState<number>(0);
    const [modalExcluir, setModalExcluir] = useState<boolean>(false);
    const [searchElement, setSearchElement] = useState<string>('');
    const [msg, setMsg] = useState('');
    const [quantityItens, setQuantityItens] = useState(0);

    const [list, setList] = useState<Array<Fita>>([]);
    const [idSelected, setIdSelected] = useState<string>('');

    useEffect( () => {
        setMsg(props.location.query.msg || '');
        getList()
    },[]);

    useEffect( () => getList() ,[pag, searchElement, limitPagination]);

    useEffect( () => {
        const newPage:Array<Number> = new Array();
        const maximumPagination = quantityItens / limitPagination;
        for(let item = 0; item < maximumPagination; item++){
            newPage.push(item)
        }
        setPage(newPage);
    } ,[quantityItens, limitPagination]);

    useEffect( () => {
        getList();
        setIdSelected('');
        setLimitPagination(5);
        setPag(0)
    } ,[msg]);


    const getList : () => void = async () => {
        const url = `/fita?limit=${limitPagination}&skip=${limitPagination * pag}&name=${searchElement}`;
        const repository = await get(url);
        setQuantityItens(repository.total);
        setList(repository.docs);
        console.log(list)
    }

    const deleteItem = async () => {
        await delet(`/fita/${idSelected}`);
        handleClose();
        setMsg('excluído');   
    }
    
    const handleClose = () => setModalExcluir(false);

    const handleShow = (item) => {
        setIdSelected(item);
        setModalExcluir(true);
    }

    const backPage = () => setPag( pag - 1 );

    const nextPage = () => setPag( pag + 1 );

    const search = (e) => setSearchElement(e);
    
    const changePagination = (e) => {
        setLimitPagination(e)
        setPag(0);
    };

    const changePag = (e) => setPag(e);

    return (
        <>
            <HeaderForConsulta
                component='Fita'
                msg={msg}
                search={search}
                name={searchElement}
                changePagination={changePagination}
                list={list.length}
                valueSelect={limitPagination}
            />
            <table className="table table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th></th>
                        <th scope="col">Nome</th>
                        <th scope="col">Preço m2</th>
                        <th scope="col">Largura</th>
                        <th scope="col">Rendimento</th>
                        <th scope="col">Valor Rolo</th>
                        <th scope="col">Descrição</th>
                    </tr>
                </thead>
                <tbody>
                    {list.length > 0 ? list.map((fita:Fita) =>
                        <tr key={fita._id}>
                            <td>
                                <ButtonsConsulta url={`alterar-fita?id=${fita._id}`}
                                  click={() => handleShow(fita._id)}
                                />
                            </td>
                            <td>{fita.nome}</td>
                            <td>
                                <CurrencyFormat displayType={'text'}
                                    value={fita.valorm2} format={CurrencyMask}>
                                </CurrencyFormat>
                            </td>
                            <td>
                                <CurrencyFormat thousandSeparator={true} suffix={'m '}
                                    displayType={'text'} value={fita.largura} >
                                </CurrencyFormat>
                            </td>
                            <td>{fita.rendimento}</td>
                            <td>
                                <CurrencyFormat displayType={'text'}
                                    value={fita.valorRolo} format={CurrencyMask}>
                                </CurrencyFormat>

                            </td>
                            <td>{fita.descricao}</td>
                        </tr>
                    ) : <MsgErroTable colunas="7" />
                    }
                </tbody>
            </table>
            <ButtonsPagination
                currentElement={ (e) => changePag(e) }
                page={page}
                currentPage={pag}
                nextPage={nextPage}
                backPage={backPage}
            />
            <ModalExcluir 
                texto={`Fita`}
                show={modalExcluir}
                hide={handleClose}
                delete={deleteItem}
            />
        </>
    )

}

export default ConsultaFita; 