import React from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CurrencyMask, CurrencyMaskForTable } from '../../helper/helperutil';
import CurrencyFormat from 'react-currency-format';
import MsgErroTable from './msg-erro-table';

const ListFornecedores = (props) =>
    <div className="row col-md-12 mb-12">
        <table className="table">
            <thead className="thead-dark">
                <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Rendimento</th>
                    <th scope="col">Custo</th>
                    <th scope="col">Valor</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.fornecedores?.length > 0 ?
                    props.fornecedores.map(fornecedor =>{
                        const id =  fornecedor?.id ? fornecedor.id : Math.random().toString(36).substring(2,7);
                        return <tr key={id}>
                            <td>{fornecedor?.nome}</td>
                            <td>
                                {fornecedor?.rendimento}
                            </td>
                            <td>
                                <CurrencyFormat displayType={'text'}
                                    value={fornecedor?.custo} format={CurrencyMask}>
                                </CurrencyFormat>
                            </td>
                            <td>
                                <CurrencyFormat displayType={'text'}
                                    value={fornecedor?.valor} format={CurrencyMaskForTable}>
                                </CurrencyFormat>
                            </td>
                            <td>
                                <button type="button" className="btn btn-danger"
                                    onClick={() => props.callback(fornecedor)}>
                                    <FontAwesomeIcon icon={faTrash} size="1x" />
                                </button>
                            </td>
                        </tr>}
                    ) :
                    <MsgErroTable colunas="5" msg="Não há fornecedores cadastrados" />
                }
            </tbody>
        </table>
    </div>
    

export default ListFornecedores;
