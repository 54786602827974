import React from 'react';
import { Link } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

const ButtonsConsulta = (props) => {
    const withOutClass = props.withOutClass === true ? 
        <div>
            <Link to={props.url}>
                <button className="btn btn-primary" type="submit" >
                    <FontAwesomeIcon icon={faEdit} size="1x" />
                </button>
            </Link>
            <button type="button" className="btn btn-danger"
                onClick={props.click}>
                <FontAwesomeIcon icon={faTrash} size="1x" />
            </button>
        </div>
        :
        <div className="row" >
            <div className="col-sm-12 col-md-3">
                <Link to={props.url}>
                    <button className="btn btn-primary" type="submit" >
                        <FontAwesomeIcon icon={faEdit} size="1x" />
                    </button>
                </Link>
            </div>
            <div className="col-sm-12 col-md-3">
                <button type="button" className="btn btn-danger"
                    onClick={props.click}>
                    <FontAwesomeIcon icon={faTrash} size="1x" />
                </button>
            </div>
        </div> 
    ;

    return withOutClass;
}
export default React.memo(ButtonsConsulta);