import { ChangeEvent } from "react";

export class Fornecedor {
   
    readonly id?: string;
    private nome?: string;
    private rendimento?: number;
    private custo?: number;
    private valor?: number;

    

    constructor(){
        this.id = Math.random().toFixed(8).replace('.', '');
    }

    get Nome(){
        return this.nome;
    }

    get Rendimento(){
        return this.rendimento;
    }

    get Custo(){
        return this.custo;
    }

    get Valor(){
        return this.valor;
    }

    inputChange = (e: ChangeEvent<HTMLInputElement>) :void => {
        const element = e.target;
        const name =  element.name.replace('-fornecedor', '');
        if('nome' == name){
            this[name] = element.value;
         }else{
            this[name] = isNaN(Number(element.value)) ? Number(element.value.replace(/\D/g, '')) : Number(element.value);
            this.calculateValor()
         }
    }

    calculateValor() :void {
        if(this.custo && this.rendimento){
            this.valor = (this.custo / this.rendimento);
        }else{
            this.valor = 0;
        }
    }
}
