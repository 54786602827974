import React, { useEffect, useState } from 'react';
import { delet, get } from '../../service/api';
import ButtonsPagination from '../../shared/components/buttons/pagination';
import ButtonsConsulta from '../../shared/components/buttons/consulta';
import ModalExcluir from '../../shared/components/modals/modal-excluir';
import MsgErroTable from '../../shared/components/util/msg-erro-table';
import HeaderForConsulta from '../../shared/components/util/header-for-consulta';
import { Tamanho } from '../../models/Tamanho';

const ConsultaTamanho = (props) => {

    const [limitPagination, setLimitPagination] = useState<number>(5);
    const [page, setPage] = useState<Array<Number>>([]);
    const [pag, setPag] = useState<number>(0);
    const [modalExcluir, setModalExcluir] = useState<boolean>(false);
    const [searchElement, setSearchElement] = useState<string>('');
    const [msg, setMsg] = useState('');
    const [quantityItens, setQuantityItens] = useState(0);

    const [list, setList] = useState<Array<Tamanho>>([]);
    const [idSelected, setIdSelected] = useState<string>('');

    useEffect( () => {
        setMsg(props.location.query.msg || '');
        getList()
    },[]);

    useEffect( () => getList() ,[pag, searchElement, limitPagination]);

    useEffect( () => {
        const newPage:Array<Number> = new Array();
        const maximumPagination = quantityItens / limitPagination;
        for(let item = 0; item < maximumPagination; item++){
            newPage.push(item)
        }
        setPage(newPage);
    } ,[quantityItens, limitPagination]);

    useEffect( () => {
        getList();
        setIdSelected('');
        setLimitPagination(5);
        setPag(0);
    } ,[msg, searchElement]);

    const getList: () => void = async () => {
        const url = `/tamanho?limit=${limitPagination}&skip=${limitPagination * pag}&nome=${searchElement}`;
        const repository = await get(url);
        setQuantityItens(repository.total);
        setList(repository.docs);
    }

    const deleteItem = async () => {
        await delet(`/tamanho/${idSelected}`);
        handleClose();
        setMsg('excluído');   
    }
    
    const handleClose = () => setModalExcluir(false);

    const handleShow = (item) => {
        setIdSelected(item ? item : '');
        setModalExcluir(true);
    }

    const backPage = () => setPag( pag - 1 );

    const nextPage = () => setPag( pag + 1 );

    const search = (e) => setSearchElement(e);
    
    const changePagination = (e) => {
        setLimitPagination(e)
        setPag(0);
    };

    const changePag = (e) => setPag(e);

    return (
        <>
            <HeaderForConsulta
                component='Tamanhos'
                msg={msg}
                search={search}
                name={searchElement}
                changePagination={changePagination}
                list={list.length}
                valueSelect={limitPagination}
            />
            <table className="table table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th></th>
                        <th scope="col">Nome</th>
                        <th scope="col">Tamanho</th>
                    </tr>
                </thead>
                <tbody>
                    {list.length > 0 ? list.map((tamanho:Tamanho) =>
                        <tr key={tamanho?._id ? tamanho._id : tamanho?._id}>
                            <td>
                                <ButtonsConsulta url={`alterar-tamanho?id=${tamanho?._id ? tamanho._id : tamanho?._id}`}
                                   click={() => handleShow(tamanho?._id ? tamanho._id : tamanho?._id)}
                                />
                            </td>
                            <td>
                                {tamanho.nome}
                            </td>
                            <td>De {tamanho.minimoLargura}cm X {tamanho.minimoAltura}cm até {tamanho.maximoLargura}cm X {tamanho.maximoAltura}cm</td>
                        </tr>
                    ) : <MsgErroTable colunas="3" />
                    }
                </tbody>
            </table>
            <ButtonsPagination
                currentElement={ (e) => changePag(e) }
                page={page}
                currentPage={pag}
                nextPage={nextPage}
                backPage={backPage}
            />
            <ModalExcluir 
                texto={`Lucro`}
                show={modalExcluir}
                hide={handleClose}
                delete={deleteItem}
            />
        </>
    );
}

export default ConsultaTamanho;