import { ChangeEvent } from "react";

class Estampa{
    readonly _id:string;
    private _nome:string;
    private _descricao:string;
    private _valor:number;
    private _silk:boolean;
    private _cores:number;

    constructor(repository:any){
        this._id = repository?._id;
        this._nome = repository?.nome;
        this._descricao = repository?.descricao;
        this._valor = repository?.valor;
        this._silk = repository?.silk;
        this._cores = repository?.cores;
    }

    get nome(){
        return this._nome;
    }

    get descricao(){
        return this._descricao;
    }

    get valor(){
        return this._valor;
    }

    get silk(){
        return this._silk;
    }

    get cores(){
        return this._cores;
    }
    
    inputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const element = e.target;
        const name = `_${element.name.replace('-estampa', '')}`;
        if(name === '_valor' || name === `_cores`){
            this[name] = isNaN(Number(element.value)) ? Number(element.value.replace(/\D/g, '')) : Number(element.value);
        } else{
            this[name] = element.value;
        }
    }

    checkBoxChange(e: ChangeEvent<HTMLInputElement>){
        const element = e.target;
        const name = `_${element.name.replace('-estampa', '')}`;
        if(element.type === `checkbox`){
            this[name] = element.checked;
        }else{
            return;
        }
    }

}

export default Estampa;
