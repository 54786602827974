import React, { useState } from 'react';
import { post } from '../../service/api';
import ButtonsSave from '../../shared/components/buttons/save';
import { browserHistory} from 'react-router';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import ComponentForm from '../../shared/components/html/component-form';
import {Tamanho} from '../../models/Tamanho'; 

export default () => {

    const [tamanho] = useState<Tamanho>(new Tamanho());

    const cadastra = async event => {
        event.preventDefault();
        const body = {
            "nome": tamanho.nome,
            "minimoLargura": tamanho.minimoLargura,
            "minimoAltura": tamanho.minimoAltura,
            "maximoLargura": tamanho.maximoLargura,
            "maximoAltura": tamanho.maximoAltura
        };
        post('/tamanho', body).then( () => browserHistory.push('/consulta-tamanho?msg=cadastrado'));
    }

    return (
        <>
            <TitlePage text={'Novo Tamanho'} />
            <ComponentForm submit={cadastra} method="post">
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="nome"
                        placeholder="Tamanho"
                        value={tamanho.nome}
                        change={tamanho.inputChange}
                        label="Nome"
                    />
                </div>
                <div className="row">
                    <Input 
                        class="col-md-12 mb-3"
                        value={tamanho.maximoAltura} 
                        change={tamanho.inputChange} 
                        label="Altura Maxima"
                        type="number" 
                        classInput="form-control col-md-3 mb-3"
                        id="maximoAltura" 
                        placeholder="Altura Max" 
                        required 
                    />
                    <Input 
                        class="col-md-12 mb-3"
                        label="Altura Minima"
                        value={tamanho.minimoAltura} 
                        change={tamanho.inputChange} 
                        type="number" 
                        classInput="form-control col-md-3 mb-3"
                        id="minimoAltura" 
                        placeholder="Altura Min" 
                        required 
                    />
                    <Input 
                        class="col-md-12 mb-3"
                        id="maximoLargura"
                        placeholder="Largura Max"
                        label="Largura Maxima"
                        type="number"
                        classInput="form-control col-md-3 mb-3"
                        value={tamanho.maximoLargura}
                        change={tamanho.inputChange}
                    />
                    <Input 
                        class="col-md-12 mb-3"
                        id="minimoLargura"
                        placeholder="Largura Minima"
                        label="Largura Minimo"
                        type="number"
                        classInput="form-control col-md-3 mb-3"
                        value={tamanho.minimoLargura}
                        change={tamanho.inputChange}
                    /> 
                </div>
                <ButtonsSave url={"/consulta-tamanho"} />
            </ComponentForm>
        </>
    )
    
}