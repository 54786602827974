import React from 'react';
import TitlePage from '../html/title';
import Message from './message';
import Search from './search';
import ElementLimitedPerPagination from './element-limited-per-pagination';

const HeaderForConsulta = (props) => (
    <React.Fragment>
        <TitlePage text={props.component} />
        <Message component={props.component} msg={props.msg} />
        {
            (props.list > 1 || props.name !== undefined) &&
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <Search 
                        callback={(e) => props.search(e)}
                        text={props.component}
                    />
                </div>
                <ElementLimitedPerPagination
                    valueSelect={props.valueSelect}
                    className="col-sm-12 col-md-6"
                    currentValue={ (e) => props.changePagination(e) }
                />
            </div>
        }
    </React.Fragment>
);

export default HeaderForConsulta;