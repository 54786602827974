import { ChangeEvent } from "react";

class Fita{
   
    readonly _id:string;
    private _nome:string;
    private _descricao:string;
    private _largura:number;
    private _valorMetro2:number;
    private _valorRolo:number;
    private _rendimento: number;

    constructor(repository:any){
        this._id = repository?._id;
        this._nome = repository?.nome;
        this._descricao = repository?.descricao;
        this._largura = repository?.largura;
        this._valorMetro2 = repository?.valorm2;
        this._valorRolo = repository?.valorRolo;
        this._rendimento = repository?.rendimento;
    }

    get nome(){
        return this._nome;
    }
    
    get descricao(){
        return this._descricao;
    }

    get largura(){
        return this._largura;
    }

    get valorm2(){
        return this._valorMetro2;
    }

    get valorRolo(){
        return this._valorRolo;
    }

    get rendimento(){
        return this._rendimento;
    }
    

    inputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const element = e.target;
        const name = `_${element.name.replace('-fita', '')}`;
        if(name === '_nome' || name === `_descricao`){
            this[name] = element.value;
        } else{
            this[name] = isNaN(Number(element.value)) ? Number(element.value.replace(/\D/g, '')) : Number(element.value);
            this.calc()
        }
    }

    inputNumberChange = (element: any, name: string) : void  => {
        if( name === '_nome' || name === `_descricao`){
            this[`_${name}`] = element.value;
        }else{
            this[`_${name}`] = element && isNaN(Number(element)) ? Number(element?.replace(/\D/g, '')) : Number(element);
            if(name === '_valorRolo'){
                this._valorRolo = (this._valorRolo  / 100);
            }
            this.calc()
        }
    }

    calc() {
         if (this._rendimento > 0 && this._valorRolo > 0){
            this._valorMetro2 = (this._valorRolo / this._rendimento);
            this._valorMetro2 = Number((this._valorMetro2/100).toFixed(2));
         }else{
             return;
         }
    }
}

export default Fita;