import axios from 'axios'
import { getToken } from './auth'
import verifyHttpErro from './verifyHttpErro'

// Define a default base URL for development.
let baseURL = 'http://localhost:3001/api';

// Check the environment to conditionally set the base URL for production.
if (process.env.NODE_ENV === 'production') {
  // Set the production base URL here.
  baseURL = 'https://appn.embalabrindes.com.br/api';
}

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(async config => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config;
})

export const post = async (url: string, body: any) => {
  try {
    await api.post(url, body)
    return true;
  } catch (err) {
    verifyHttpErro(err)
    return false;
  }
}

export const get = async (url: string) => {
  try {
    const { data: repository } = await api.get(url)
    return repository;
  } catch (err) {
    verifyHttpErro(err)
    return {
      docs: null,
      total: null

    };
  }
}

export const delet = async (url: string) => {
  try {
    await api.delete(url)
    return true;
  } catch (err) {
    verifyHttpErro(err)
    return false;
  }
}

export const put = async (url: string, body: any) => {
  try {
    await api.put(url, body)
    return true;
  } catch (err) {
    verifyHttpErro(err)
    return false;
  }
}


export default api