import React from 'react';

import Header from './header'
import BarraLateral from './barra-lateral'

 const MenuPrincipal = () =>  
(
    <div>
        <Header/>
        <BarraLateral/>
    </div>
);
export default MenuPrincipal;
    

