import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import api from '../../service/api';
import {CurrencyMask} from '../../shared/helper/helperutil';
import ButtonsAlter from '../../shared/components/buttons/update';
import TitlePage from '../../shared/components/html/title';
import Select from '../../shared/components/html/select';
import Input from '../../shared/components/html/input';
import InputNumber from '../../shared/components/html/input-for-number';
import Cordao from '../../models/Cordao';
import ComponentForm from  '../../shared/components/html/component-form' 

const AlterarCordao = (props: Readonly<any>) => {

    const [id] = useState<string>(props.location.query.id);
    const [cordao, setCordao] = useState<Cordao>(new Cordao(null));
    const [msg, setMsg] = useState('');

    
    useEffect(() => {
        if (id) {
            getItem();
        }else{
            goTo();
        }
    }, []);


    const getItem = async () => {
        try {
            const { data: repository } = await api.get(`/cordao/${id}`)
            if(!repository) goTo();
            setCordao(new Cordao(repository));
            setMsg('here')
        } catch (err) {
            console.error(err)
        }
    }

    const goTo = () => browserHistory.push('/consulta-cordao');

    const altera = async event => {
        event.preventDefault();
        try {
            setMsg(`Cordão: ${cordao.nome} atualizado com sucesso!`)
            await api.put(`/cordao/${cordao._id}?msg=${msg}`,
                {
                    "nome": cordao.nome,
                    "descricao": cordao.descricao,
                    "tipo": cordao.tipo,
                    "rendimento": cordao.rendimento,
                    "espessura": cordao.espessura,
                    "custo": cordao.custo
                }
            )
            browserHistory.push('/consulta-cordao?msg=atualizado')
        } catch (err) {
            setMsg('Houve um problema com o login, verifique seu usuário e senha!' );
        }
    }
    
    return (
        <>
            <TitlePage text={'Atualizar Cordão'} />
            <ComponentForm submit={altera}>
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="nome"
                        placeholder="Nome do cordão"
                        value={cordao.nome || ''}
                        change={(e) => {cordao.inputChange(e); setMsg(e.target.value)}}
                        label="Nome"
                        required={true}
                    />
                    <Select value={cordao.tipo || 'kilo'} 
                        class="col-md-3 mb-3" 
                        id="tipo" label="Tipo" 
                        change={(e) => {cordao.inputChange(e); setMsg(e.target.value)}} required={true} >
                        <option value="kilo">kilo</option>
                        <option value="rolo">rolo</option>
                    </Select>
                    
                    <InputNumber
                        label="Custo"
                        class="col-md-3 mb-3"
                        prefix={'R$'}
                        format={CurrencyMask}
                        value={cordao.custo || 0}
                        onChange={(e) => {cordao.inputChange(e); setMsg(e.target.value)}}
                        id="custo"
                        placeholder="R$ 0,00"
                    />
                </div>
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="rendimento"
                        placeholder="Rendimento do cordão"
                        value={cordao.rendimento || ''}
                        change={(e) => {cordao.inputChange(e); setMsg(e.target.value)}}
                        label="Rendimento"
                        required={true}
                    />
                    <InputNumber
                        label="Espessura"
                        class="col-md-6 mb-3"
                        thousandSeparator={true} 
                        suffix={' m'}
                        value={cordao.espessura || 0}
                        onChange={(e) => {cordao.inputChange(e); setMsg(e.target.value)}}
                        id="espessura"
                        placeholder="Espessura do cordão"
                    />
                </div>
                <div className="row">
                    <Input 
                        class="col-md-12 mb-3"
                        id="descricao"
                        placeholder="Descricao do cordão"
                        value={cordao.descricao || ''}
                        change={(e) => {cordao.inputChange(e); setMsg(e.target.value)}}
                        label="Descricao"
                        required={true}
                    />
                </div>
                <ButtonsAlter url={"cordao"}/>
            </ComponentForm>
        </>
    );
}

export default AlterarCordao;