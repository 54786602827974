import React from 'react'

 const Card = (props:any) =>  (
    <div className="card">
        <div className="card-header" id="headingOne">
            <h2 className="mb-0">
                <button className="btn btn-link" type="button" data-toggle="collapse" data-target={`#${props.id}`} aria-expanded="true" aria-controls={props.id}>
                    {props.title}
                </button>
            </h2>
        </div>
        <div id={props.id} className={`collapse ${props.className}`} aria-labelledby="headingOne" data-parent="#collapseOne">
            <div className="card-body">
                {props.children}
            </div>
        </div>
    </div>
);

export default React.memo(Card);