import React from 'react'
import CurrencyFormat from 'react-currency-format';

 const InputNumber = (props :any) => {
    return (
    <div className={props.class ? props.class : "col-md-3 mb-3 " }>
        <label htmlFor={props.id}>{props.label}</label>
        <CurrencyFormat 
            name={props.id}
            thousandSeparator={ props.thousandSeparator} 
            suffix={props.suffix}
            ref={props.func}
            id={props.id} 
            placeholder={props.placeholder}
            className={ props.classInput ? props.classInput : "form-control" }
            format={props.format}
            prefix={props.prefix}
            onValueChange={props.onValueChange}
            onChange={props.onChange}
            value={props.value}
            displayType={props.type}
        />
    </div>
);}
export default React.memo(InputNumber);