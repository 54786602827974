import React, { Component } from 'react';
import { browserHistory, Link } from 'react-router';
import api from '../../service/api';
import { FormatCurrencyForBackEnd, StringForNumber } from '../../shared/helper/helperutil';
import SelectEasy from '../../shared/components/html/select-easy';
import TitlePage from '../../shared/components/html/title';
import ButtonsUpdate from '../../shared/components/buttons/update';

let isValid
export default class AlteraOrcamento extends Component {

    constructor(props) {
        super(props)
        isValid = !!this.props.location.query.id ? true : false
        if (!isValid) {
            browserHistory.push('/consulta-orcamento')
        }
        this.state = {
            itemId: this.props.location.query.id, itemAlteracao: '',
            tecidos: [{ nome: 'Selecione um tecido', _id: "" }],
            tecidoSelecionado: "",
            estampas: [{ nome: 'Selecione uma estampa', _id: "" }],
            estampaSelecionada: "",
            costuras: [{ nome: 'Selecione uma costura', _id: "" }],
            costuraSelecionada: "",
            custosIndiretos: [{ nome: 'Selecione um custo indireto', _id: "" }],
            custoIndiretoSelecionado: "",
            custoLinhas: [{ nome: 'Selecione um custo de linha', _id: "" }],
            custoLinhaSelecionada: "",
            acessorios: [{ nome: 'Selecione um acessorio', _id: "" }],
            acessorioSelecionado: "",
            cordoes: [{ nome: 'Selecione um cordão', _id: "" }],
            cordaoSelecionado: "",
            fitas: [{ nome: 'Selecione uma fita', _id: "" }],
            fitaSelecionada: ""
        }
    }

    componentWillMount = async () => {
        if (isValid) {
            this.getItem()
        }
    }

    getItem = async () => {
        try {
            const { data: repository } = await api.get(`/orcamento/${this.state.itemId}`)
            this.setState({ itemAlteracao: repository })
        } catch (err) {
            console.error(err)
        }
    }

    altera = async event => {
        event.preventDefault()
        try {
            await api.put(`/orcamento/${this.state.itemId}`,
                {
                    "nome": this.state.itemAlteracao.nome,
                    "descricao": this.state.itemAlteracao.descricao,
                    "valor": FormatCurrencyForBackEnd(this.state.itemAlteracao.valor),
                    "largura": StringForNumber(this.state.itemAlteracao.largura)
                }
            )
            browserHistory.push('/consulta-orcamento?msg=atualizado')
        } catch (err) {
            console.error(err)
            this.setState({ msg: 'Houve um problema com o login, verifique seu usuário e senha!' })

        }
    }

    render() {
        return (
            <div>
                <TitlePage text={'Atualizar Orçamento'} />
                
                <form className="needs-validation" onSubmit={this.cadastra} method="post">
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <label htmlFor="empresa">Empresa</label>
                            <input type="text" className="form-control" ref={(empresa) => this.empresa = empresa}
                                id="empresa" placeholder="Empresa" required >
                            </input>
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="contato">Contato</label>
                            <input type="text" className="form-control" ref={(contato) => this.contato = contato}
                                id="contato" placeholder="Contato" required >
                            </input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <label htmlFor="email">Email</label>
                            <input type="email" className="form-control" ref={(email) => this.email = email}
                                id="email" placeholder="Email" required >
                            </input>
                        </div>
                        <div className="col-md-4 mb-3">
                            <label htmlFor="telefone">Telefone</label>
                            <input type="phone" className="form-control" ref={(telefone) => this.telefone = telefone}
                                id="telefone" placeholder="Telefone" required >
                            </input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <label htmlFor="tipo">Tipo</label>
                            <input type="text" className="form-control" ref={(tipo) => this.tipo = tipo}
                                id="tipo" value="Saco"  >
                            </input>
                        </div>
                        <div className="col-md-3 mb-3">
                            <label htmlFor="quantidade">Quantidade</label>
                            <input type="number" className="form-control" ref={(quantidade) => this.quantidade = quantidade}
                                id="quantidade" placeholder="Quantidade" required >
                            </input>
                        </div>
                        <div className="col-md-3 mb-3">
                            <label htmlFor="altura">Altura</label>
                            <input type="text" className="form-control" ref={(altura) => this.altura = altura}
                                id="altura" placeholder="Altura" required >
                            </input>
                        </div>
                        <div className="col-md-3 mb-3">
                            <label htmlFor="largura">Largura</label>
                            <input type="text" className="form-control" ref={(largura) => this.largura = largura}
                                id="largura" placeholder="Largura" required >
                            </input>
                        </div>
                    </div>
                    <div className="row">
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Tecido'}
                            itemSelecionado={this.state.tecidoSelecionado}
                            dados={this.state.tecidos}
                            callback={(event) => this.setState({ tecidoSelecionado: event }, () => this.calculoCorteVT())}
                        />
                        <div className="col-md-3 mb-3">
                            <label htmlFor="corte">Corte(VT)</label>
                            <input type="text" className="form-control" ref={(corte) => this.corte = corte}
                                id="corte" placeholder="Corte(VT)"  >
                            </input>
                        </div>
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Estampa'}
                            itemSelecionado={this.state.estampaSelecionada}
                            dados={this.state.estampas}
                            callback={(event) => this.setState({ estampaSelecionada: event })}
                        />
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Costura'}
                            itemSelecionado={this.state.costuraSelecionada}
                            dados={this.state.costuras}
                            callback={(event) => this.setState({ costuraSelecionada: event})}
                        />
                    </div>
                    <div className="row">
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Custo Indireto'}
                            itemSelecionado={this.state.custoIndiretoSelecionado}
                            dados={this.state.custosIndiretos}
                            callback={(event) => this.setState({ custoIndiretoSelecionado: event.target.value }, () => this.calculoLiquido())}
                        />
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Custo Linha'}
                            itemSelecionado={this.state.custoLinhaSelecionada}
                            dados={this.state.custoLinhas}
                            callback={(event) => this.setState({ custoLinhaSelecionada: event }, () => this.calculoLiquido())}
                        />
                        <div className="col-md-3 mb-3">
                            <label htmlFor="maoDeObra">Mão de Obra</label>
                            <input type="text" className="form-control" ref={(maoDeObra) => this.maoDeObra = maoDeObra}
                                id="maoDeObra" placeholder="Mão de Obra"  >
                            </input>
                        </div>
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Acessório'}
                            itemSelecionado={this.state.acessorioSelecionado}
                            dados={this.state.custoLinhas}
                            callback={(event) => this.setState({ acessorioSelecionado: event }, () => this.calculoLiquido())}
                        />
                    </div>
                    <div className="row">
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Cordão'}
                            itemSelecionado={this.state.cordaoSelecionado}
                            dados={this.state.cordoes}
                            callback={(event) => this.setState({ cordaoSelecionado: event })}
                        />
                        <SelectEasy className="col-md-3 mb-3"
                            texto={'Fita'}
                            itemSelecionado={this.state.fitaSelecionada}
                            dados={this.state.fitas}
                            callback={(event) => this.setState({ fitaSelecionada: event })}
                        />
                        <div className="col-md-3 mb-3">
                            <label htmlFor="nome-orcamento">Frete</label>
                            <input type="text" className="form-control" ref={(frete) => this.frete = frete}
                                id="frete" placeholder="Frete"  >
                            </input>
                        </div>
                    </div>

                    <ButtonsUpdate url={'orcamento'} />
                </form>
            </div >
        )
    }
}