import React from 'react';

const ElementLimitedPerPagination = (props) => (
    <div className="dataTables_length bs-select" id="elementLimited">
        <label>Quantidade de itens:
            <select 
                value={props.valueSelect}
                name="elementLimited"
                aria-controls="elementLimited"
                className="custom-select custom-select-sm form-control form-control-sm"
                onChange={(event) => props.currentValue(event.target.value)}>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
            </select>
        </label>
    </div>
)
export default React.memo(ElementLimitedPerPagination);