import React from 'react'

const SelectEasy = (props) => {
    return (<div className={props.className}>
        <label htmlFor={`element${props.texto}`}>{props.texto}</label>
        <select className="form-control"
            id={`element${props.texto}`}
            value={props.itemSelecionado}
            onChange={(event) => props.callback(event.target.value)}>
            {
                props.firstOption && 
                <option value='' >
                    {`Selecione ${props.firstOption} `}
                </option>
            }
            {props.dados &&
                props.dados.map((option) =>
                    <option 
                        key={`${option.id}${option.nome}`}
                        value={option.id} 
                        defaultValue={option.id === props.itemSelecionado}
                    >
                        {option.nome || option.quantidade}
                    </option>
                )
            }
        </select>
    </div>)
}

export default React.memo(SelectEasy);