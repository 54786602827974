import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import api from '../../service/api';
import { PercentMask } from '../../shared/helper/helperutil'
import TitlePage from '../../shared/components/html/title';
import ButtonsUpdate from '../../shared/components/buttons/update';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import Lucro from '../../models/Lucro';
import ComponentForm from '../../shared/components/html/component-form';

const AlterarLucro  = (props:any) => {

    const [lucro, setLucro] = useState<Lucro>(new Lucro(null));
    const [msg, setMsg] = useState<string>(``); 
    const [id] = useState<string>(props?.location?.query?.id);

    useEffect(() =>{
        if(id){
            getItem();
        }else{
            setMsg(`Lucro escolhido esta indisponivel no momento!`)
            goTo();
        }
    }, [])

    const getItem = async () => {
        try {
            const { data: repository } = await api.get(`/lucro/${id}`)
            setLucro(new Lucro(repository))
        } catch (err) {
            console.error(err)
        }
    }

    const altera = async event => {
        event.preventDefault();
        try {
            await api.put(`/lucro/${id}`,
                {
                    "quantidade": lucro.quantidade,
                    "valor": lucro.valor
                }
            ).then( () =>{
                setMsg(`Lucro atualizado com sucesso!`);
                goTo()
            } )
            
        } catch (err) {
            setMsg('Houve um problema com o login, verifique seu usuário e senha!')

        }
    }

    const goTo = () => browserHistory.push(`/consulta-lucro?msg=${msg}`);

    return (
        <>
            <TitlePage text={'Atualizar lucro'} />
            <ComponentForm submit={altera} method="put">
                <div className="row">
                    <Input 
                        value={lucro.quantidade || ''}
                        change={ (e) => {lucro.inputChange(e); setMsg(e.target.value)}}
                        class="col-md-6 mb-3"
                        id="quantidade-lucro"
                        placeholder="Quantidade do lucro"
                        label="Quantidade"
                        required={true}
                    />
                    <InputNumber
                        value={lucro.valor}
                        onChange={ (e) => {lucro.inputChange(e); setMsg(e.target.value)}}
                        label="Valor"
                        class="col-md-6 mb-3"
                        suffix={'%'}
                        format={PercentMask}
                        id="valor-lucro"
                        placeholder="0 %"
                    />
                </div>
                <ButtonsUpdate url={'lucro'} />
            </ComponentForm>
        </>
    );
}

export default AlterarLucro;