import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt} from '@fortawesome/free-solid-svg-icons'


const UpdateStatus =  (props) => (
    <div>
        <button type="button" className="btn btn-success"
            onClick={props.click}>
            <FontAwesomeIcon icon={faSyncAlt} size="1x" />
        </button>
    </div>
);
export default React.memo(UpdateStatus);