import React from 'react'

 const ComponentForm = (props : any) =>  (
    <div className="col-md-8 order-md-2">
        <form className="needs-validation" onSubmit={props?.submit} method="post">
            {props?.children}
        </form>
    </div>
);

export default React.memo(ComponentForm);