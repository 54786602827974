import React from 'react';

const Message = (props) => (
    props.msg && 
    <div id="alert" className="alert alert-success alert-dismissible fade show" role="alert">
        {props.component} <strong>{props.msg}</strong> com sucesso
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
);
export default React.memo(Message);