import React from 'react';
import { Link } from 'react-router';
import Funcionalidades from './funcionalidades';

const BarraLateral =  () => (
    <div className="container-fluid">
        <div className="row">
            <nav className="col-md-2 d-none d-md-block bg-light sidebar">
                <div className="sidebar-sticky">
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span><h6>Orçamentos</h6></span>
                    </h6>
                    <ul className="nav flex-column mb-2">
                        <Link className="dropdown-item" to={"/cadastro-orcamento"}><h6>Novo</h6></Link>
                        <Link className="dropdown-item" to={"/consulta-orcamento"}><h6>Consultar</h6></Link>
                    </ul>
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span>Cadastros</span>
                    </h6>
                    <ul className="nav flex-column">
                        <Funcionalidades navegacao="-acessorio"  span="Acessórios" />
                        <Funcionalidades navegacao="-cordao"  span="Cordões" />
                        <Funcionalidades navegacao="-costura"  span="Costuras" />
                        <Funcionalidades navegacao="-custo-indireto"  span="Custos Indiretos" />
                        <Funcionalidades navegacao="-custo-linha"  span="Custos de Linhas" />
                        <Funcionalidades navegacao="-estampa"  span="Estampas" />
                        <Funcionalidades navegacao="-fita"  span="Fitas" />
                        <Funcionalidades navegacao="-fotolito"  span="Fotolitos" />
                        <Funcionalidades navegacao="-imposto"  span="Impostos" />
                        <Funcionalidades navegacao="-lucro"  span="Lucros" />
                        <Funcionalidades navegacao="-tamanho"  span="Tamanhos" />
                        <Funcionalidades navegacao="-tecido"  span="Tecidos" />
                    </ul>
                </div>
            </nav>
        </div>
    </div>
);
export default BarraLateral;
