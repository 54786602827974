import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { post, get } from '../../service/api';
import { CurrencyMask } from '../../shared/helper/helperutil';
import ButtonsSave from '../../shared/components/buttons/save';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import ComponentForm from '../../shared/components/html/component-form';
import Costura from '../../models/Constura';
import { Tamanho } from '../../models/Tamanho';
import Select from '../../shared/components/html/select';

const CadastroCostura = () => {

    const [costura] = useState<Costura>(new Costura(null));
    const [tamanhos, setTamanhos] = useState<Array<Tamanho>>([]);
    const [msg, setMsg] = useState<string>(``);
    
    useEffect(() => {
        getTamanhos()
        setMsg(`Tamanhos!`)
    }, []);

    const cadastra = async event => {
        event.preventDefault();
        const body = {
            "nome": costura.nome,
            "descricao": costura.descricao,
            "valor": costura.valor,
            "tempo": costura.tempo,
            "tamanho": costura.tamanhoForPayload
        };
        post('/costura', body).then(() => browserHistory.push('/consulta-costura?msg=cadastrada'))
    }

    const getTamanhos: () => void = async () => {
        const {docs: repository}  = await get(`/tamanho`);
        repository?.map(tamanho => setTamanhos(prev => [...prev, ...new Array(new Tamanho(tamanho))] ) )
        setMsg(`Tamanhos up!`)
    }

    return (
        <>
            <TitlePage text={'Nova Costura'} />
            <ComponentForm submit={cadastra} method="post">
                    <div className="row">
                        <Input 
                            class="col-md-6 mb-3"
                            id="nome-costura"
                            placeholder="Nome da costura"
                            value={costura.nome}
                            change={costura.inputChange}
                            label="Nome"
                            required={true}
                        />
                        <InputNumber
                            label="Valor"
                            class="col-md-6 mb-3"
                            prefix={'R$'}
                            format={CurrencyMask}
                            value={costura.valor}
                            onChange={costura.inputChange}
                            id="valor-costura"
                            placeholder="R$ 0,00"
                        />
                    </div>
                    <div className="row">
                        <Input 
                            class="col-md-6 mb-3"
                            id="tempo-costura"
                            placeholder="Tempo da costura"
                            value={costura.tempo}
                            change={costura.inputChange}
                            label="Tempo"
                            required={true}
                        />

                        <Select
                            class="col-md-6 mb-3"
                            id="tamanho-costura"
                            label="Tamanho da Costura"
                            value={costura.tamanho}
                            required={true}
                            change={(e) => costura.tamanhoSelected(e, tamanhos)}
                        >
                            {
                                tamanhos?.map((tamanho:Tamanho) =>
                                    <option 
                                        key={tamanho?._id}
                                        value={tamanho?._id}
                                        defaultValue={tamanho._id}
                                    >
                                        {tamanho.nome}
                                    </option>
                                ) 
                            }
                        </Select>
                    </div>
                    <div className="row">
                        <Input 
                            class="col-md-12 mb-3"
                            id="descricao-costura"
                            placeholder="Descrição do Costura"
                            value={costura.descricao}
                            change={costura.inputChange}
                            label="Descrição"
                            required={true}
                        />
                    </div>
                    <ButtonsSave
                        url={"/consulta-costura"}
                    />
            </ComponentForm>
        </>
    );
}
export default React.memo(CadastroCostura);