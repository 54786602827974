import React, { Component, useEffect, useState } from 'react';
import { delet, get, put } from '../../service/api';
import ButtonsConsulta from '../../shared/components/buttons/consulta';
import ButtonsPagination from '../../shared/components/buttons/pagination';
import UpdateStatus from '../../shared/components/buttons/update-status';
import ElementLimitedPerPagination from '../../shared/components/util/element-limited-per-pagination';
import ModalExcluir from '../../shared/components/modals/modal-excluir';
import ModalUpdateStatus from '../../shared/components/modals/modal-update-status';
import MsgErroTable from '../../shared/components/util/msg-erro-table';
import Search from '../../shared/components/util/search';
import TitlePage from '../../shared/components/html/title';
import Orcamento from '../../models/Order/Orcamento';

const ConsultaOrcamento = (props: any) => {

    const [limitPagination, setLimitPagination] = useState<number>(5);
    const [page, setPage] = useState<Array<Number>>([]);
    const [pag, setPag] = useState<number>(0);
    const [modalExcluir, setModalExcluir] = useState<boolean>(false);
    const [showUpdateStatus, setShowUpdateStatus] = useState<boolean>(false);
    const [searchElement, setSearchElement] = useState<string>('');
    const [msg, setMsg] = useState('');
    const [quantityItens, setQuantityItens] = useState(0);
    const [state, setState] = useState({});

    const [list, setList] = useState<Array<Orcamento>>([]);
    const [idSelected, setIdSelected] = useState<string>('');

    useEffect( () => {
        setMsg(props.location.query.msg || '');
        getList()
    },[]);

    useEffect( () => getList() ,[pag, searchElement, limitPagination]);

    useEffect( () => {
        const newPage:Array<Number> = new Array();
        const maximumPagination = quantityItens / limitPagination;
        for(let item = 0; item < maximumPagination; item++){
            newPage.push(item)
        }
        setPage(newPage);
    } ,[quantityItens, limitPagination]);

    useEffect( () => {
        getList();
        setIdSelected('');
        setLimitPagination(5);
        setPag(0)
    } ,[msg]);
    
    const getList: () => void =  async () => {
        const repository = await get(`/orcamento?limit=${limitPagination}&skip=
        ${limitPagination * pag}&name=`);
        setQuantityItens(repository.total);
        setList(repository.docs);
    }

    const deleteItem = async () => {
        await delet(`/orcamento/${idSelected}`)
        handleClose()
        setMsg('excluído')
        getList();
    }

    const handleCloseUpdateStatus = () => {
        setShowUpdateStatus(false)
    }
    
    const setStatus = async () => {
        const  item = list.find((res) => res._id === idSelected);
        //item.status = status ? status : 'Rascunho';
        //delete item._id;
        const continua = await put(`/orcamento/${idSelected}`, item)
        if(continua){
            handleCloseUpdateStatus();
            getList();
        }

    }

    const handleClose = () => setModalExcluir(false);

    const handleShow = (item) => {
        setIdSelected(item);
        setModalExcluir(true);
    }

    const backPage = () => setPag( pag - 1 );

    const nextPage = () => setPag( pag + 1 );

    const search = (e) => setSearchElement(e);
    
    const changePagination = (e) => {
        setLimitPagination(e)
        setPag(0);
    };

    const changePag = (e) => setPag(e);

    
        return (
            <div>
                <TitlePage text={'Orçamentos'} />
                {msg.length > 0 &&
                    <div id="alert" className="alert alert-success alert-dismissible fade show" role="alert">
                        Orçamento <strong>{msg}</strong> com sucesso
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                }
                {
                    list &&
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <Search
                                callback={(res) => setState({ name: res })}
                                text="Orçamentos"
                            />
                        </div>
                        <ElementLimitedPerPagination
                            className="col-sm-12 col-md-6"
                            currentValue={
                                (res) => setState({ limitPagination: res })
                            }
                        />
                    </div>
                }
                <table className="table table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th></th>
                            <th scope="col">Solicitante</th>
                            <th scope="col">Status</th>
                            <th scope="col">Contato</th>
                            <th scope="col">Estado/CEP</th>
                            <th scope="col">Produto</th>
                            <th scope="col">Quantidade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list ? list.map((orcamento:Orcamento) =>
                             <tr key={`${orcamento._id}-${orcamento.status}`}>
                                <td>
                                    <div className="row">
                                        <ButtonsConsulta url={`alterar-orcamento?id=${orcamento._id}`}
                                            withOutClass={true}
                                            click={() => { setIdSelected(orcamento._id); handleShow('') }}
                                        />
                                        <UpdateStatus url={`alterar-orcamento?id=${orcamento._id}`}
                                            click={() => setIdSelected(orcamento._id)}
                                        />
                                    </div>
                                </td>
                                <td>{orcamento?.cliente?.solicitante}<br></br>{orcamento.cliente?.nome ? `(${orcamento.cliente.nome})` : ''}</td>
                                <td>{orcamento.status}</td>
                                <td>{orcamento.cliente?.telefone}<br></br>{orcamento.cliente?.email}</td>
                                <td>{orcamento.cliente?.endereco?.cep}<br></br>{orcamento.cliente?.endereco?.cidade}</td>
                                {/* <td>{orcamento.}</td> */}
                                <td>{orcamento?.quantidade}</td>
                            </tr>
                        ) : <MsgErroTable colunas="6" />
                        }
                    </tbody>
                </table>
               

                <ButtonsPagination
                    currentElement={ (e) => changePag(e) }
                    page={page}
                    currentPage={pag}
                    nextPage={nextPage}
                    backPage={backPage}
                />
                <ModalExcluir 
                    texto={`Imposto`}
                    show={modalExcluir}
                    hide={handleClose}
                    delete={deleteItem}
                />
                <ModalUpdateStatus
                    texto={'orcamento'}
                    show={showUpdateStatus}
                    hide={handleCloseUpdateStatus}
                    status={(res) => setState({status: res})}
                    callback={ () => setStatus()}
                />
            </div>
        )
    
}

export default ConsultaOrcamento;