import React from 'react';
import './app.css'
import Menu from './shared/menu/menu-principal';

 const App = (props) => 
  (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Menu></Menu>
          <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
            {props.children}
          </main>
        </div>
      </div>
    </div>
  );
export default App;