import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import './login.css'
import logo from '../assets/logo_embalabrindes.png'
import { login } from '../service/auth'
import api from '../service/api'

export default class Login extends Component {

    constructor(props) {
        super(props)
        this.state = { msg: this.props.location.query.msg };
    }

    login = async event => {
        event.preventDefault();
        try {
            const response = await api.post('/auth', {
                email: this.user.value,
                password: this.password.value
            })
            login(response.data.token)
            browserHistory.push('/consulta-orcamento')
        } catch (err) {
            this.setState({ msg: 'Houve um problema com o login, verifique seu usuário e senha!' })

        }
    }

    render() {
        let msg = null
        if (this.state.msg) {
            msg =
                <div className="alert alert-danger" role="alert">
                    {this.state.msg}
                </div>
        }
        return (
            <form className="form-signin" onSubmit={this.login} method="post">
                <img className="mb-4" src={logo} alt="" width="100%" height="auto" />
                {msg}
                <h1 className="h4 mb-3 font-weight-normal text-center">Módulo de Orçamento</h1>
                <label htmlFor="inputUser" className="sr-only">Usuario</label>
                <input type="email" id="inputUser" className="form-control"
                    placeholder="Usuário" required autoFocus ref={(user) => this.user = user} >
                </input>
                <label htmlFor="inputPassword" className="sr-only">Senha</label>
                <input type="password" id="inputPassword" className="form-control"
                    placeholder="Senha" required ref={(password) => this.password = password} >
                </input>
                <button className="btn btn-lg btn-primary btn-block" >Entrar</button>
            </form>
        );
    }

}
