import React from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const AddFornecedor = (props) =>
    <div className="row col-md-12 mb-3">
        <div id="accordionExample">
            <div className="card">
                <div id="headingOne">
                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <FontAwesomeIcon icon={faPlus} size="1x" />
                        {" Fornecedor"}
                    </button>
                </div>
                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="card-body">
                        <div className="row">
                            {props.children}
                        </div>
                        <div className="row col-xs-6 col-sm-4">
                            <button className="btn btn-primary " type="button"
                                data-toggle="collapse" data-target="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne"
                                onClick={props.addFornecedor}>
                                <FontAwesomeIcon icon={faPlus} size="1x" />
                                {" Add"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

export default React.memo(AddFornecedor);