import { ChangeEvent } from "react";

class Fotolito{
    readonly _id:string;
    private _nome:string;
    private _quantidade:number;
    private _valor:number;

    constructor(repository: any){
        this._id = repository?._id;
        this._nome = repository?.nome;
        this._quantidade = repository?.quantidade;
        this._valor = repository?.valor;
    }

    get nome(){
        return this._nome;
    }

    get quantidade(){
        return this._quantidade;
    }

    get valor(){
        return this._valor;
    }

    inputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const element = e.target;
        const name = `_${element.name.replace('-fotolito', '')}`;
        if(name === '_nome'){
            this[name] = element.value;
        } else{
            this[name] = isNaN(Number(element.value)) ? Number(element.value.replace(/\D/g, '')) : Number(element.value);
        }
    }
}

export default Fotolito;