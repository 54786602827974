import React from 'react';
import { Link } from 'react-router'

const ButtonsUpdate = (props) => (
    <div className="row">
        <div className="col-md-3 order-md-2">
            <button className="btn btn-success btn-lg btn-block" type="submit">
                Atualizar
            </button>
        </div>
        <div className="col-md-3 order-md-2">
            <Link to={'/consulta-' + props.url}>
                <button className="btn btn-info btn-lg btn-block" type="button">
                    Cancelar
                </button>
            </Link>
        </div>
    </div>
);
export default React.memo(ButtonsUpdate);