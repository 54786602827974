import { ChangeEvent } from "react";
import Acessorio from "../Acessorio";
import Costura from "../Constura";
import Cordao from "../Cordao";
import CustoIndireto from "../CustoIndireto";
import CustoLinha from "../CustoLinha";
import Estampa from "../Estampa";
import Fita from "../Fita";
import Fotolito from "../Fotolito";
import Imposto from "../Imposto";
import Lucro from "../Lucro";
import Cliente from "../costumer/Cliente";
import CorteVT from "./CorteVT";
import Tecido from "../Tecido";

class Orcamento{
    readonly _id:string;
    private _cliente?: Cliente;
    private _estampa?: Estampa;
    private _costura?: Costura;
    private _custoIndireto?: CustoIndireto;
    private _custoLinha?: CustoLinha;
    private _acessorio?: Acessorio;
    private _cordao?: Cordao;
    private _fita?: Fita;
    private _imposto?: Imposto;
    private _lucro?: Lucro;
    private _fotolito?: Fotolito;
    private _corteVT?: CorteVT;
    private _status?: string;
    private _maoDeObra?: number;
    private _frete?: number;
    private _quantidade:number;
    
    constructor(repository:any){
        this._id = repository?.id;
        this._cliente = repository?.cliente;
        this._estampa = repository?.estampa;
        this._costura = repository?.costura;
        this._custoIndireto = repository?.custoIndireto;
        this._custoLinha = repository?.custoLinha;
        this._maoDeObra = repository?.maoDeObra;
        this._acessorio = repository?.acessorio;
        this._cordao = repository?.cordao;
        this._fita = repository?.fita;
        this._frete = repository?.frete;
        this._imposto = repository?.imposto;
        this._lucro = repository?.lucro;
        this._fotolito = repository?.fotolito;
        this._corteVT = repository?.corteVt;
        this._status = repository?.status;
        this._quantidade = repository?.quantidade;
    }

    get cliente(){ return this._cliente;}
    
    get estampa(){ return this._estampa;}

    get costura(){ return this._costura;}

    get custoIndireto(){ return this._custoIndireto;}

    get custoLinha(){ return this._custoLinha;}

    get maoDeObra(){ return this._maoDeObra;}

    get acessorio(){ return this._acessorio;}

    get cordao(){ return this._cordao;}

    get fita(){ return this._fita;}

    get frete(){ return this._frete;}

    get imposto(){ return this._imposto;}

    get lucro() { return this._lucro;}

    get fotolito() { return this._fotolito;}

    get corteVT() { return this._corteVT;}

    get status(){ return this._status;}

    get quantidade(){ return this._quantidade;}

    setcorteVT(corte: CorteVT){
        this._corteVT = corte;
    }

    setCliente(client: Cliente){
        this._cliente = client;
    }

    getBody(){
        return this;
    }


    inputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const element = e.target;
        const name = `_${element.name.replace('-orcamento', '')}`;
        if(name === '_maoDeObra' || name === '_frete' ){
            this[name] = isNaN(Number(element.value)) ? Number(element.value.replace(/\D/g, '')) : Number(element.value);
        } else{
            this[name] = element.value;
        }
    }

    tecidoSelected = (e: ChangeEvent<HTMLInputElement>, tecido:Array<Tecido>) :void => {
        debugger;
        const element = e.target;
        //this._ = tecido.find(r => (r.nome === element.value) || (r._id === element.value) );
    } 
}

enum Status{
    FATURADO = 'FATURADO',
    CANCELADO = 'CANCELADO',
    ATENDIDO = 'ATENDIDO',
    PENDENTE = 'PENDENTE',
}


export default Orcamento;