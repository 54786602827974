import React from 'react'

const Search = (props) =>(
    <div id="dtBasicExample_filter" className="dataTables_filter justify-content-end">
        <label>
            Pesquisar {props.text}:
            <input type="search" 
                className="form-control form-control-sm" 
                placeholder="" 
                aria-controls="dtBasicExample"
                onChange={(event) => (event.target.value.length >= 2 || event.target.value === ``) 
                    && props.callback(event.target.value)} 
            />
        </label>
    </div>
);
export default React.memo(Search);
