import { ChangeEvent } from "react";

class Lucro{
    readonly _id:string;
    private _quantidade:number;
    private _valor:number;

    constructor(repository:any){
        this._id = repository?._id;
        this._quantidade = repository?.quantidade;
        this._valor = repository?.valor;
    }

    get quantidade(){
        return this._quantidade;
    }

    get valor(){
        return this._valor;
    }

    inputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const element = e.target;
        const name = `_${element.name.replace('-lucro', '')}`;
        this[name] = isNaN(Number(element.value)) ? Number(element.value.replace(/\D/g, '')) : Number(element.value);
    }
}

export default Lucro;