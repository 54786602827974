import React, { useState } from 'react'
import { post } from '../../service/api';
import  ButtonsSave  from '../../shared/components/buttons/save';
import { browserHistory } from 'react-router';
import { CurrencyMask, FormatCurrencyForBackEnd } from '../../shared/helper/helperutil';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import ComponentForm from '../../shared/components/html/component-form';
import CustoLinha from '../../models/CustoLinha';


const CadastroCustoLinha = () => {

    const [custo] = useState<CustoLinha>(new CustoLinha(null));
    const [msg, setMsg] = useState<string>('')

    const cadastra = async event => {
        event.preventDefault();
        const body = {
            "nome": custo.nome,
            "descricao": custo.descricao,
            "valor": custo.valor,
        };
        setMsg(`Custo de linha: ${custo.nome} cadastrado com sucesso!`)
        post('/custo/linha', body).then(browserHistory.push(`/consulta-custo-linha?msg=${msg}`));
    }

    return (
        <>
            <TitlePage text={'Novo Custo de linha'} />
            <ComponentForm submit={cadastra} method="post">
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="nome-linha"
                        placeholder="Nome linha"
                        value={custo.nome}
                        change={custo.inputChange}
                        label="Nome"
                        required={true}
                    />
                    <InputNumber
                        label="Preço linha"
                        class="col-md-6 mb-3"
                        prefix={'R$'}
                        format={CurrencyMask}
                        value={custo.valor}
                        onChange={ custo.inputChange}
                        id="valor-linha"
                        placeholder="R$ 0,00"
                    /> 
                </div>
                <div className="row">
                    <Input 
                        class="col-md-12 mb-3"
                        id="descricao-linha"
                        placeholder="Descrição da linha"
                        value={custo.descricao}
                        change={custo.inputChange}
                        label="Descrição"
                        required={true}
                    />
                </div>
                <ButtonsSave url={"/consulta-custo-linha"} />
            </ComponentForm>
        </>
    )
}

export default CadastroCustoLinha;