import { Component } from 'react';
import {browserHistory} from  'react-router';
import {deleteToken} from '../service/auth'

export default class Logout extends Component {

    componentWillMount(){
        deleteToken()
        browserHistory.push('/');
    }

    render(){
        return null;
    }
}