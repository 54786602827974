import React, { Component, useEffect, useState } from 'react';
import { browserHistory, Link } from 'react-router';
import api from '../../service/api';
import { CurrencyMask, CurrencyMaskForTable, FormatCurrencyForBackEnd } from '../../shared/helper/helperutil';
import ButtonsAlter from '../../shared/components/buttons/update';
import TitlePage from '../../shared/components/html/title';
import  Input  from '../../shared/components/html/input';
import  InputNumber  from '../../shared/components/html/input-for-number';
import ComponentForm from '../../shared/components/html/component-form';
import Estampa from '../../models/Estampa';

const AlterarEstampa = (props) => {

   const [estampa, setEstampa] = useState<Estampa>(new Estampa(null));
   const [msg, setMsg] = useState<string>(``);
   const [id] = useState<string>(props?.location?.query?.id);

   useEffect(() =>{
        if(id){
            getItem()
        }else{
            goTo()
        }
   }, [])

    const getItem = async () => {
        try {
            const { data: repository } = await api.get(`/estampa/${id}`);
            setEstampa(new Estampa(repository));
        } catch (err) {
            console.error(err);
        }
    }

    const altera = async event => {
        event.preventDefault();
        try {
            await api.put(`/estampa/${id}`,
                {
                    "nome": estampa.nome,
                    "descricao": estampa.descricao,
                    "valor": estampa.valor,
                    "silk": estampa.silk,
                    "cores": estampa.cores
                }
            ).then(() => {
                setMsg(`Estampa: ${estampa.nome} atualizado com sucesso!`);
                goTo();
            });
        } catch (err) {
            setMsg('Houve um problema com o login, verifique seu usuário e senha!');
        }
    }

    const goTo = () => browserHistory.push(`/consulta-estampa?${msg ? `msg=${msg}` : ``}`)
    
    return (
        <>
            <TitlePage text={'Atualizar Estampa'} />
            <ComponentForm submit={altera} method="put">
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="nome-estampa"
                        placeholder="Nome da estampa"
                        value={estampa.nome }
                        change={ (e) => {estampa.inputChange(e); setMsg(e.target.value)}}
                        label="Nome"
                        required={true}
                    />
                    <InputNumber
                        label="Preço da estampa"
                        class="col-md-6 mb-3"
                        prefix={'R$'}
                        format={CurrencyMask}
                        value={estampa.valor}
                        onChange={ (e) => {estampa.inputChange(e); setMsg(e.target.value)}}
                        id="valor-estampa"
                        placeholder="R$ 0,00"
                    />
                </div>
                <div className="row">
                    <Input 
                        type="checkbox"
                        class="col-md-2 mb-6"
                        id="silk"
                        placeholder="Silkscreen"
                        value={true}
                        defaultChecked={estampa.silk}
                        click={ (e) => {estampa.checkBoxChange(e); setMsg(e.target.value)}}
                        label="Silkscreen"
                    />
                    <Input 
                        type="number"
                        class="col-md-3 mb-6"
                        id="cores"
                        placeholder="Quantidade de cores"
                        value={estampa.cores}
                        change={ (e) => {estampa.inputChange(e); setMsg(e.target.value)}}
                        label="Quantidade de cores"
                        required={true}
                    />
                </div>
                <div className="row">
                    <Input 
                        class="col-md-12 mb-3"
                        id="descricao-estampa"
                        placeholder="Descrição da estampa"
                        value={estampa.descricao}
                        change={ (e) => {estampa.inputChange(e); setMsg(e.target.value)}}
                        label="Descrição"
                        required={true}
                    />
                </div>
                <ButtonsAlter url={"estampa"}/>
            </ComponentForm>
        </>
    )
}

export default React.memo(AlterarEstampa);
