import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import { post } from '../../service/api';
import { CurrencyMask} from '../../shared/helper/helperutil';
import ButtonsSave from '../../shared/components/buttons/save';
import TitlePage from '../../shared/components/html/title';
import Select from '../../shared/components/html/select';
import Input from '../../shared/components/html/input';
import InputNumber from '../../shared/components/html/input-for-number';
import ComponentForm from '../../shared/components/html/component-form';
import Cordao from '../../models/Cordao';


const CadastroCordao = () => {

    const [cordao] = useState<Cordao>(new Cordao(null));
    const [msg, setMsg] = useState<string>(``);

    const cadastra = async event => {
        event.preventDefault();
        const body = {
            "nome": cordao.nome,
            "descricao": cordao.descricao,
            "tipo": cordao.tipo,
            "rendimento": cordao.rendimento,
            "espessura": cordao.espessura,
            "custo": cordao.custo,
        };
        setMsg(`Cordao ${cordao.nome} cadastrado com sucesso!`);
        post('/cordao', body).then(() => browserHistory.push(`/consulta-cordao?msg=${msg}` ));
    }
    
    return (
        <>
            <TitlePage text={'Novo Cordão'} />
            <ComponentForm submit={cadastra} method="post">
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="nome"
                        placeholder="Nome do cordão"
                        value={cordao.nome}
                        change={cordao.inputChange}
                        label="Nome"
                        required={true}
                    />
                    <Select value={cordao.tipo} 
                        class="col-md-3 mb-3" 
                        id="tipo" label="Tipo" 
                        change={(e) => {cordao.inputChange(e); setMsg(e.target.value)}} required={true} >
                        <option value="kilo">kilo</option>
                        <option value="rolo">rolo</option>
                    </Select>
                    <InputNumber
                            label="Custo"
                            class="col-md-3 mb-3"
                            prefix={'R$'}
                            format={CurrencyMask}
                            value={cordao.custo}
                            onChange={cordao.inputChange}
                            id="custo"
                            placeholder="R$ 0,00"
                    />
                </div>
                <div className="row">
                    <Input 
                        class="col-md-6 mb-3"
                        id="rendimento"
                        placeholder="Rendimento do cordão"
                        value={cordao.rendimento}
                        change={cordao.inputChange}
                        label="Rendimento"
                        required={true}
                    />
                    <InputNumber
                        label="Espessura"
                        class="col-md-6 mb-3"
                        thousandSeparator={true} 
                        suffix={' m'}
                        value={cordao.espessura}
                        onChange={cordao.inputChange}
                        id="espessura"
                        placeholder="Espessura do cordão"
                    />
                </div>
                <div className="row">
                    <Input 
                        class="col-md-12 mb-3"
                        id="descricao"
                        placeholder="Descricao do cordão"
                        value={cordao.descricao}
                        change={cordao.inputChange}
                        label="Descricao"
                        required={true}
                    />
                </div>
                <ButtonsSave url={"/consulta-cordao"} />
            </ComponentForm>
        </>
    );
}
export default CadastroCordao;